import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase"; // Adjust the path to your firebase config

const AddEvents = ({ store }) => {
  const [events, setEvents] = useState([]);
  const [formData, setFormData] = useState({
    Name: "",
    StoreID: store ? store._id : "",
    startDate: "",
    endDate: "",
    Photos: [],
    Description: "",
    Category: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [editEventId, setEditEventId] = useState(null);
  const toast = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();
  const [deleteEventId, setDeleteEventId] = useState(null);

  useEffect(() => {
    if (store && store._id) {
      setFormData((prevData) => ({
        ...prevData,
        StoreID: store._id,
      }));
    }
    fetchEvents();
  }, [store]);

  const fetchEvents = async () => {
    try {
      const token = Cookies.get("notatmrp_seller");
      const res = await axios.get(
        `https://api.notatmrp.com/store-events/${store._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      setEvents(res.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "Photos") {
      setFormData({
        ...formData,
        [name]: files,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = Cookies.get("notatmrp_seller");

    try {
      const uploadFileAndGetUrl = async (file, folder) => {
        const storageRef = ref(storage, `${folder}${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
      };

      const photoUrls = await Promise.all(
        Array.from(formData.Photos).map((file) =>
          uploadFileAndGetUrl(file, `events/${store._id}/`)
        )
      );

      const newFormData = {
        ...formData,
        Photos: photoUrls,
      };

      if (editMode) {
        await axios.put(
          `https://api.notatmrp.com/store-events/${editEventId}`,
          newFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
            },
          }
        );
        toast({
          title: "Event updated.",
          description: "The event has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await axios.post(
          `https://api.notatmrp.com/store-events/${store._id}`,
          newFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
            },
          }
        );
        toast({
          title: "Event added.",
          description: "The event has been added successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      fetchEvents();
      setEditMode(false);
      setEditEventId(null);
      setFormData({
        Name: "",
        StoreID: store._id,
        startDate: "",
        endDate: "",
        Photos: [],
        Description: "",
        Category: "",
      });
    } catch (error) {
      console.error("Error adding/updating event:", error);
      toast({
        title: "Error",
        description: `An error occurred while ${
          editMode ? "updating" : "adding"
        } the event.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (event) => {
    setEditMode(true);
    setEditEventId(event._id);
    setFormData({
      Name: event.Name,
      StoreID: event.StoreID,
      startDate: new Date(event.startDate).toISOString().split("T")[0],
      endDate: new Date(event.endDate).toISOString().split("T")[0],
      Photos: [],
      Description: event.Description,
      Category: event.Category,
    });
  };

  const handleDelete = (id) => {
    setDeleteEventId(id);
    setIsOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const token = Cookies.get("notatmrp_seller");
      await axios.delete(
        `https://api.notatmrp.com/store-events/${deleteEventId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "Event deleted.",
        description: "The event has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchEvents();
    } catch (error) {
      console.error("Error deleting event:", error);
      toast({
        title: "Error",
        description: "An error occurred while deleting the event.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      onClose();
    }
  };

  return (
    <Box bg="white" p={6} rounded="md" shadow="md">
      <Heading mb={6} color="purple.600">
        {editMode ? "Edit Event" : "Add Event"}
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="Name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="Name"
              value={formData.Name}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="startDate" isRequired>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="endDate" isRequired>
            <FormLabel>End Date</FormLabel>
            <Input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="Photos">
            <FormLabel>Photos</FormLabel>
            <Input
              type="file"
              name="Photos"
              multiple
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="Description" isRequired>
            <FormLabel>Description</FormLabel>
            <Textarea
              name="Description"
              value={formData.Description}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="Category" isRequired>
            <FormLabel>Category</FormLabel>
            <Input
              type="text"
              name="Category"
              value={formData.Category}
              onChange={handleInputChange}
            />
          </FormControl>
          <Button type="submit" colorScheme="purple" width="full">
            {editMode ? "Update Event" : "Add Event"}
          </Button>
        </VStack>
      </form>
      <Box mt={10} className="overflow-auto">
        <Heading mb={6} color="purple.600">
          Events List
        </Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Start Date</Th>
              <Th>End Date</Th>
              <Th>Description</Th>
              <Th>Category</Th>
              <Th>Photos</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {events.map((event) => (
              <Tr key={event._id}>
                <Td>{event.Name}</Td>
                <Td>{new Date(event.startDate).toLocaleDateString()}</Td>
                <Td>{new Date(event.endDate).toLocaleDateString()}</Td>
                <Td>{event.Description}</Td>
                <Td>{event.Category}</Td>
                <Td>
                  {event.Photos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo}
                      alt={`Event ${index + 1}`}
                      style={{ width: "50px", marginRight: "5px" }}
                    />
                  ))}
                </Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => handleEdit(event)}
                  >
                    Edit
                  </Button>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => handleDelete(event._id)}
                    ml={2}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Event
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this event? This action cannot be
              undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default AddEvents;
