import React from "react";
import "./overall-list.scss";
import { MdStickyNote2 } from "react-icons/md";
import { FaStore } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";

const icons = [
  <span className="text-[30px] font-bold ">
    <MdStickyNote2 />
  </span>,
  <span className="text-[30px] font-bold ">
    <FaRegUser />
  </span>,
  <span className="text-[30px] font-bold ">
    <FaStore />
  </span>,
  <span className="text-[30px] font-bold ">
    <FaRupeeSign />
  </span>,
];

const OverallList = ({ orders }) => {
  // Calculate totals
  const totalOrders = orders.length;
  const totalRevenue = orders.reduce(
    (sum, order) => sum + order.DiscountedPrice,
    0
  );

  return (
    <ul className="overall-list">
      <li className="overall-list__item">
        <div className="overall-list__item__icon">{icons[0]}</div>
        <div className="overall-list__item__info">
          <div className="title">Orders</div>
          <span>{totalOrders}</span>
        </div>
      </li>
      {/* <li className="overall-list__item">
        <div className="overall-list__item__icon">{icons[1]}</div>
        <div className="overall-list__item__info">
          <div className="title">Click</div>
          <span>0</span>
        </div>
      </li>
      <li className="overall-list__item">
        <div className="overall-list__item__icon">{icons[2]}</div>
        <div className="overall-list__item__info">
          <div className="title">Impression</div>
          <span>0</span>
        </div>
      </li> */}
      <li className="overall-list__item">
        <div className="overall-list__item__icon">{icons[3]}</div>
        <div className="overall-list__item__info">
          <div className="title">Revenue</div>
          <span>{totalRevenue}</span>
        </div>
      </li>
    </ul>
  );
};

export default OverallList;
