import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoutes = ({ children }) => {
  const token = Cookies.get("notatmrp_seller");
  return token ? children : <Navigate to="/login" />;
  //   return token ? <Navigate to="/login" /> : children;
};

export default ProtectedRoutes;
