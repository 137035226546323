import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import axios from "axios";
import { QrReader } from "react-qr-reader";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";
import "./editor.css"; // Ensure this has relevant styles for your QR overlay

export default function Booking({ store }) {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const [scannedTicket, setScannedTicket] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scanError, setScanError] = useState(false);
  const toast = useToast();
  const token = Cookies.get("notatmrp_seller");

  useEffect(() => {
    if (!store || !store._id) return;

    const fetchBookedUsers = async () => {
      try {
        const response = await axios.get(
          `https://api.notatmrp.com/tickets/all/store/${store._id}`,
          {
            headers: {
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const allUsers = response.data.flatMap((ticket) => ticket.bookedUsers);
        setUsers(allUsers);
      } catch (error) {
        console.error("Error fetching booked users: ", error);
        toast({
          title: "Error.",
          description: "There was an error fetching the booked users.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchBookedUsers();
  }, [store, token, toast]);

  const handleSearch = () => {
    return users.filter(
      (user) =>
        user.phone.includes(searchQuery) ||
        user.OrderNumber.includes(searchQuery)
    );
  };

  const handleScan = (data) => {
    if (data) {
      setIsScanning(false);
      navigator.vibrate(200);
      const foundTicket = users.find((user) => user.OrderNumber === data);

      if (foundTicket) {
        setScannedTicket(foundTicket);
        setScanError(false);
      } else {
        setScanError(true);
      }

      setIsModalOpen(true);
    }
  };

  const handleError = (err) => {
    console.error("QR Reader Error: ", err);
    toast({
      title: "QR Reader Error",
      description: "An error occurred while reading the QR code.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleStartScan = () => {
    setIsScanning(true);
    setScannedTicket(null);
    setScanError(false);
  };

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(users);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BookedUsers");
    XLSX.writeFile(workbook, "booked_users.xlsx");
  };

  const tableWidth = useBreakpointValue({ base: "100%", md: "80%", lg: "60%" });

  // Calculate total quantity
  const totalQuantity = users.reduce(
    (acc, user) => acc + (user.quantity || 0),
    0
  );

  // console.log(users);
  return (
    <Box bg="purple.50" p={6} minH="100vh">
      <Box bg="white" p={6} rounded="md" mx="auto" overflowX="auto">
        <Heading mb={6} color="purple.800">
          Booked Users
        </Heading>

        <Box mb={4}>
          <Input
            placeholder="Search by phone or Order No"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>

        <Button
          colorScheme="purple"
          mb={4}
          onClick={handleStartScan}
          disabled={isScanning}
        >
          {isScanning ? "Scanning..." : "Scan Ticket via QR"}
        </Button>

        {isScanning && (
          <Box mb={6} className="qr-scanner-container">
            <QrReader
              delay={300}
              onError={handleError}
              onResult={(result, error) => {
                if (result) {
                  handleScan(result?.text);
                }
                if (error) {
                  // Avoids flooding console if many errors occur
                  if (error.name !== "NotAllowedError") {
                    handleError(error);
                  }
                }
              }}
              constraints={{
                facingMode: { exact: "environment" }, // Use environment camera
              }}
              style={{ width: "100%" }}
            />
            <div className="qr-overlay">
              <div className="qr-scanner-box"></div>
              <span className="scan-instruction">
                Align QR code within the box
              </span>
            </div>
          </Box>
        )}

        <Button colorScheme="green" mx={2} mb={4} onClick={handleDownloadExcel}>
          Download as Excel
        </Button>

        {/* Display total quantity of booked items */}
        <Box mb={4} color="purple.600">
          <Heading size="md">Total Quantity of Books: {totalQuantity}</Heading>
        </Box>

        <Table variant="simple" size="sm" colorScheme="purple">
          <Thead>
            <Tr>
              <Th>Order Number</Th>
              <Th>Name</Th>
              <Th>Phone</Th>
              <Th>Email</Th>
              <Th>Gender</Th>
              <Th isNumeric>Quantity</Th>
              <Th>Ticket Price</Th>
              <Th>Total Amount</Th>

              <Th>Transaction ID</Th>
              <Th>Refer Code</Th>
            </Tr>
          </Thead>
          <Tbody>
            {handleSearch().map((user) => (
              <Tr key={user._id.$oid}>
                <Td>{user.OrderNumber || "N/A"}</Td>

                <Td>{user.fullname || "N/A"}</Td>
                <Td>{user.phone || "N/A"}</Td>
                <Td>{user.email || "N/A"}</Td>
                <Td>{user.gender || "N/A"}</Td>
                <Td isNumeric>{user.quantity || 0}</Td>
                <Td isNumeric>{user.ticketPrice || 0}</Td>
                <Td isNumeric>{user.totalAmount || 0}</Td>

                <Td>{user.transactionid}</Td>
                <Td>{user.refer || ""}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {scanError ? "No Detail Found" : "Scanned Ticket Details"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {scanError ? (
              <p style={{ color: "red" }}>
                No details found for the scanned transaction ID.
              </p>
            ) : scannedTicket ? (
              <>
                <p>
                  <strong>Name:</strong> {scannedTicket.fullname}
                </p>
                <p>
                  <strong>Phone:</strong> {scannedTicket.phone}
                </p>
                <p>
                  <strong>Transaction ID:</strong> {scannedTicket.transactionid}
                </p>
                <p>
                  <strong>Quantity:</strong> {scannedTicket.quantity}
                </p>
                <p>
                  <strong>Email:</strong> {scannedTicket.email}
                </p>
              </>
            ) : (
              <p>Scanning in progress...</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
