import axios from "axios";
import Cookies from "js-cookie";
import { useState, useEffect, useRef } from "react";

const useStoreData = (storeId) => {
  const [store, setStore] = useState(null);
  const [error, setError] = useState(null);

  // useRef to store the cached store data
  const cachedStore = useRef({});

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("notatmrp_seller");

      if (token && storeId) {
        try {
          let storeData = cachedStore.current[storeId];

          if (!storeData) {
            // If cache is empty or data is not cached, fetch from API
            const res = await axios.get(
              `https://api.notatmrp.com/auth/retailer/stores/${storeId}`,
              {
                headers: {
                  "x-api-key":
                    "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            storeData = res.data;
            cachedStore.current[storeId] = storeData; // Store in cache
          }

          setStore(storeData);
        } catch (error) {
          console.error("Failed to fetch store data:", error);
          setError("Failed to fetch store data");
        }
      } else {
        setError("No token found or missing storeId");
      }
    };

    fetchData();
    // Remove the dependency array to run on every render cycle
  }, [storeId]); // Add storeId to the dependency array

  return { store };
};

export default useStoreData;
