import React from "react";
import { Link } from "react-router-dom";
import mobileapp from "../../assets/images/mobileapp.png";
import bgImage from "../../assets/bg.png";

const MidPart = () => {
  return (
    <div className="bg-purple-700 p-[20px] md:mx-[30px] mb-5 rounded-[30px]">
      <div className="lg:flex bg-white max-w-screen-xl mx-auto rounded-[30px]">
        <div
          className="lg:w-1/2 flex items-center pt-[20px]  bg-cover bg-center "
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <img
            src={mobileapp}
            alt="mobile app"
            className="h-[500px] object-contain"
          />
        </div>

        <div className="lg:w-1/2 mx-auto px-4 py-16">
          <h1 className="text-4xl font-bold text-center text-primary">
            Unleash Your Potential: Empowering Businesses with NOT@MRP
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white shadow-lg p-6 rounded-lg">
              <h2 className="text-2xl font-semibold text-purple-600 mb-4">
                Sellers
              </h2>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>
                  <strong>Enhanced Visibility:</strong> Gain greater online
                  presence and attract more customers by partnering with
                  NOT@MRP.
                </li>
                <li>
                  <strong>Increased Foot Traffic:</strong> Drive more customers
                  to your store through our platform’s exclusive deals and
                  promotions.
                </li>
                <li>
                  <strong>Customer Loyalty:</strong> Build a loyal customer base
                  with our instant cashback and reward programs.
                </li>
                <li>
                  <strong>Competitive Edge:</strong> Compete on an equal footing
                  with larger market players and expand your reach.
                </li>
                <li>
                  <strong>Seamless Integration:</strong> Easily integrate modern
                  technology into your business operations, simplifying
                  transactions and improving customer engagement.
                </li>
              </ul>
            </div>
            <div className="bg-white shadow-lg p-6 rounded-lg">
              <h2 className="text-2xl font-semibold text-purple-600 mb-4">
                Buyers
              </h2>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>
                  <strong>Instant Savings:</strong> Enjoy exclusive deals and
                  instant cashback when you shop at our partner stores.
                </li>
                <li>
                  <strong>Seamless Shopping:</strong> Scan a QR code or pay
                  bills through our app for a smooth, integrated shopping
                  experience.
                </li>
                <li>
                  <strong>Wide Selection:</strong> Access a diverse range of
                  local businesses and unique products, all in one place.
                </li>
                <li>
                  <strong>Personalized Offers:</strong> Receive tailored
                  promotions and rewards, making every purchase more rewarding.
                </li>
                <li>
                  <strong>Support Local:</strong> Contribute to the growth of
                  your local community by shopping locally and building a loyal
                  customer base.
                </li>
              </ul>
            </div>
          </div>
          <Link
            to="#"
            className="text-center text-white bg-primary py-2 px-4 rounded-md hover:bg-opacity-75 font-bold"
          >
            Join the NOTATMRP revolution today!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MidPart;
