import { GoHomeFill } from "react-icons/go";
import { FaMoneyBill } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";
import { MdEvent } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { MdSell } from "react-icons/md";
import { FaHouseUser } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";
import { FaStore } from "react-icons/fa";

const sidebarNav = [
  {
    link: "/",
    section: "home",
    icon: (
      <span className="text-[30px] font-bold ">
        <GoHomeFill />
      </span>
    ),
    text: "Home",
  },

  {
    link: "/cashback",
    section: "cashback",
    icon: (
      <span className="text-[30px] font-bold ">
        <FaMoneyBill />
      </span>
    ),
    text: "Cashback",
  },

  {
    link: "/addoffers",
    section: "addoffers",
    icon: (
      <span className="text-[30px] font-bold ">
        <BiSolidOffer />
      </span>
    ),
    text: "Add offers",
  },

  {
    link: "/addevents",
    section: "addevents",
    icon: (
      <span className="text-[30px] font-bold ">
        <MdEvent />
      </span>
    ),
    text: "Add Event",
  },

  {
    link: "/addnotification",
    section: "addnotification",
    icon: (
      <span className="text-[30px] font-bold ">
        <IoIosNotifications />
      </span>
    ),
    text: "Add Notification",
  },

  {
    link: "/addmenu",
    section: "addmenu",
    icon: (
      <span className="text-[30px] font-bold ">
        <MdOutlineRestaurantMenu />
      </span>
    ),
    text: "Add Menu",
  },
  {
    link: "/orders",
    section: "orders",
    icon: (
      <span className="text-[30px] font-bold ">
        <MdSell />
      </span>
    ),
    text: "Orders",
  },

  // {
  //   link: "/products",
  //   section: "products",
  //   icon: <i className="bx bx-cube"></i>,
  //   text: "Products",
  // },
  // {
  //   link: "/customers",
  //   section: "customers",
  //   icon: (
  //     <span className="text-[30px] font-bold ">
  //       <FaHouseUser />
  //     </span>
  //   ),
  //   text: "Customer List",
  // },

  {
    link: "/settings",
    section: "settings",
    icon: (
      <span className="text-[30px] font-bold ">
        <FaStore />
      </span>
    ),
    text: "Edit Store",
  },

  {
    link: "/editprofile",
    section: "editprofile",
    icon: (
      <span className="text-[30px] font-bold ">
        <IoIosSettings />
      </span>
    ),
    text: "Edit Profile",
  },
];

export default sidebarNav;
