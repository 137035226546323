import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  Text,
  Image,
  IconButton,
  Heading,
} from "@chakra-ui/react";
import axios from "axios";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase"; // Adjust the import based on your file structure
import Cookies from "js-cookie";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

export default function AddMenu({ store }) {
  const [menuName, setMenuName] = useState("");
  const [images, setImages] = useState([]);
  const [menus, setMenus] = useState([]);
  const toast = useToast();

  useEffect(() => {
    fetchMenus();
  }, []);

  const fetchMenus = async () => {
    try {
      const token = Cookies.get("notatmrp_seller");
      const response = await axios.get(
        `https://api.notatmrp.com/stores/${store._id}/menu`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      setMenus(response.data);
      // console.log("Menu", response);
    } catch (error) {
      console.error("Error fetching menus:", error);
      toast({
        title: "An error occurred.",
        description: "Unable to fetch menus.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleFileChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const uploadPromises = images.map(async (image) => {
        const storageRef = ref(storage, `menus/${image.name}`);
        await uploadBytes(storageRef, image);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
      });

      const urls = await Promise.all(uploadPromises);

      const token = Cookies.get("notatmrp_seller");
      const data = {
        StoreID: store._id,
        MenuName: menuName,
        Images: urls,
      };

      await axios.post("https://api.notatmrp.com/menus", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key":
            "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
        },
      });

      toast({
        title: "Menu added.",
        description: "Your menu has been successfully added.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setMenuName("");
      setImages([]);
      fetchMenus();
    } catch (error) {
      console.error("Error adding menu:", error);
      toast({
        title: "An error occurred.",
        description: "Unable to add menu.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (menuId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this menu?"
    );
    if (!confirmDelete) return;

    try {
      const token = Cookies.get("notatmrp_seller");
      await axios.delete(`https://api.notatmrp.com/menus/${menuId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key":
            "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
        },
      });

      toast({
        title: "Menu deleted.",
        description: "The menu has been successfully deleted.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      fetchMenus();
    } catch (error) {
      console.error("Error deleting menu:", error);
      toast({
        title: "An error occurred.",
        description: "Unable to delete menu.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (menu) => {
    setMenuName(menu.MenuName);
    setImages(menu.Images);
  };

  return (
    <Box>
      <VStack
        spacing={8}
        mt={8}
        p={8}
        boxShadow="md"
        bg="white"
        borderRadius="md"
      >
        <Heading mb={6} color="purple.600">
          Add Menu
        </Heading>
        <form onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel>Menu Name</FormLabel>
            <Input
              type="text"
              value={menuName}
              onChange={(e) => setMenuName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Upload Images</FormLabel>
            <Input
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileChange}
            />
          </FormControl>
          <Button type="submit" colorScheme="purple" mt={4}>
            Add Menu
          </Button>
        </form>
        <Box w="full" mt={8}>
          <Heading mb={6} color="purple.600">
            Menus List
          </Heading>
          {menus.map((menu) => (
            <Box
              key={menu._id}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              mb={4}
            >
              <Text fontSize="xl" mb={4}>
                {menu.MenuName}
              </Text>
              <Box d="flex" flexWrap="wrap">
                {menu.Images.map((url, index) => (
                  <Image
                    key={index}
                    src={url}
                    boxSize="100px"
                    objectFit="cover"
                    mr={4}
                    mb={4}
                  />
                ))}
              </Box>
              <Box d="flex" justifyContent="flex-end">
                <IconButton
                  icon={<EditIcon />}
                  mr={2}
                  onClick={() => handleEdit(menu)}
                />
                <IconButton
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  onClick={() => handleDelete(menu._id)}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </VStack>
    </Box>
  );
}
