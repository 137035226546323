import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"; 

const firebaseConfig = {
  apiKey: "AIzaSyB37iuqLju8ajqua3Ka5mO4vqAJ_9MXHXM",
  authDomain: "notatmrp.firebaseapp.com",
  databaseURL: "https://notatmrp-default-rtdb.firebaseio.com",
  projectId: "notatmrp",
  storageBucket: "notatmrp.appspot.com",
  messagingSenderId: "909851226710",
  appId: "1:909851226710:web:f72e9f558cddd4e05401f7",
  measurementId: "G-E0BRGYEK1H",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app); 

export { app, analytics, storage }; 
