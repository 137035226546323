import React from "react";
import "./main-layout.scss";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import TopNav from "../components/topnav/TopNav";
import EventSidebar from "../components/sidebar/EventSidebar";
import { useStoreContext } from "../context/StoreContext";

const MainLayout = () => {
  const { user, store, hasSpecialStoreCategory } = useStoreContext();
  const navigate = useNavigate();

  // If no stores are associated with the user, redirect to complete profile
  React.useEffect(() => {
    if (user && user.stores && user.stores.length === 0) {
      navigate("/completeprofile");
    }
  }, [user, navigate]);

  return (
    <>
      {hasSpecialStoreCategory ? (
        <>
          <EventSidebar />
          <div className="main">
            <div className="main__content">
              <TopNav />
              <Outlet />
            </div>
          </div>
        </>
      ) : (
        <>
          <Sidebar />
          <div className="main">
            <div className="main__content">
              <TopNav />
              <Outlet />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MainLayout;
