import React from "react";
import lab from "../../assets/images/lab.png";
import startup from "../../assets/images/startup.png";

export default function InfoPart() {
  return (
    <div className="max-w-screen-xl mt-10 mx-auto px-6">
      <h2 className="text-[30px] lg:text-[40px] font-bold text-center mb-5">
        Achievements
      </h2>

      <div className="md:w-full bg-white mt-8  mb-5">
        <h2 className="text-xl font-bold text-purple-600 mb-4">
          ₹4 Lakh+ Sales Achievement
        </h2>
        <p className="text-lg text-center p-6 rounded-md shadow-md">
          NOT@MRP has proudly partnered with leading brands and achieved over ₹4
          lakh in affiliate sales.
        </p>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-10 space-y-8 md:space-y-0">
        <div className="md:w-1/2 bg-white ">
          <h2 className="text-xl font-bold text-purple-600 mb-4">
            Incubated with Venture Lab TIET
          </h2>
          <img
            src={lab}
            alt="Incubated with Thapar University"
            className="w-full h-48 object-contain rounded-md shadow-md"
          />
        </div>
        <div className="md:w-1/2 bg-white ">
          <h2 className="text-xl font-bold  text-purple-600 mb-4">
            DPIIT Recognized Startup of India (CNO. DIPP161513)
          </h2>
          <img
            src={startup}
            alt="DPIIT Recognized Startup of India (DIPP161513)"
            className="w-full h-48 object-contain rounded-md shadow-md"
          />
        </div>
      </div>
    </div>
  );
}
