import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";

const AddNotification = ({ store }) => {
  const [notifications, setNotifications] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    StoreID: "",
    targetCity: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [editNotificationId, setEditNotificationId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteNotificationId, setDeleteNotificationId] = useState(null);
  const cancelRef = React.useRef();
  const toast = useToast();

  useEffect(() => {
    if (store && store._id) {
      setFormData((prevData) => ({
        ...prevData,
        StoreID: store._id,
      }));
    }
    fetchNotifications();
  }, [store]);

  const fetchNotifications = async () => {
    try {
      const token = Cookies.get("notatmrp_seller");

      const storeId = store._id;
      const res = await axios.get(
        `https://api.notatmrp.com/notifications/store/${storeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      setNotifications(res.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = Cookies.get("notatmrp_seller");

      if (editMode) {
        await axios.patch(
          `https://api.notatmrp.com/notifications/${editNotificationId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
            },
          }
        );
        toast({
          title: "Notification updated.",
          description: "The notification has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await axios.post("https://api.notatmrp.com/notifications", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        });
        toast({
          title: "Notification added.",
          description: "The notification has been added successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      fetchNotifications();
      setEditMode(false);
      setEditNotificationId(null);
      setFormData({
        title: "",
        description: "",
        StoreID: store._id,
        targetCity: "",
      });
    } catch (error) {
      console.error("Error adding/updating notification:", error);
      toast({
        title: "Error",
        description: `An error occurred while ${
          editMode ? "updating" : "adding"
        } the notification.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (notification) => {
    setEditMode(true);
    setEditNotificationId(notification._id);
    setFormData({
      title: notification.title,
      description: notification.description,
      StoreID: notification.StoreID,
      targetCity: notification.targetCity,
    });
  };

  const handleDelete = async () => {
    try {
      const token = Cookies.get("notatmrp_seller");
      await axios.delete(
        `https://api.notatmrp.com/notifications/${deleteNotificationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "Notification deleted.",
        description: "The notification has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchNotifications();
      setIsDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting notification:", error);
      toast({
        title: "Error",
        description: "An error occurred while deleting the notification.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteNotificationId(id);
    setIsDeleteDialogOpen(true);
  };

  return (
    <Box bg="white" p={6} rounded="md" shadow="md">
      <Heading mb={6} color="purple.600">
        {editMode ? "Edit Notification" : "Add Notification"}
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="title" isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="description" isRequired>
            <FormLabel>Description</FormLabel>
            <Textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="targetCity">
            <FormLabel>Target City</FormLabel>
            <Input
              type="text"
              name="targetCity"
              value={formData.targetCity}
              onChange={handleInputChange}
            />
          </FormControl>
          <Button type="submit" colorScheme="purple" width="full">
            {editMode ? "Update Notification" : "Add Notification"}
          </Button>
        </VStack>
      </form>
      <Box mt={10} className="overflow-auto">
        <Heading mb={6} color="purple.600">
          Notifications List
        </Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th>Description</Th>
              <Th>Target City</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {notifications.map((notification) => (
              <Tr key={notification._id}>
                <Td>{notification.title}</Td>
                <Td>{notification.description}</Td>
                <Td>{notification.targetCity}</Td>
                <Td>
                  {/* <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => handleEdit(notification)}
                    mr={2}
                  >
                    Edit
                  </Button> */}
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => handleDeleteClick(notification._id)}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Notification
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this notification? This action
              cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setIsDeleteDialogOpen(false)}
              >
                No
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default AddNotification;
