import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useToast } from "@chakra-ui/react";

const useLogout = () => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleLogout = async () => {
    try {
      setLoading(true);

      // Display loading toast
      toast({
        title: "Logging out...",
        status: "info",
        duration: 3000,
        isClosable: false,
      });

      const token = Cookies.get("notatmrp_seller");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,

          "x-api-key":
            "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
        },
      };
      const res = await axios.post(
        `https://api.notatmrp.com/auth/retailer/logout`,
        null,
        config
      );

      // Remove token from cookies
      Cookies.remove("notatmrp_seller");

      // Redirect to login page
      window.location.href = "/login";

      toast({
        title: res.data.message || "Logout successful",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Logout error:", error);
      toast({
        title: "Logout failed",
        description: "An error occurred while logging out.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { handleLogout, loading };
};

export default useLogout;
