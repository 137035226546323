import axios from "axios";
import Cookies from "js-cookie";

export async function Logout(toast) {
  try {
    const token = Cookies.get("notatmrp_seller");

    // Call logout API
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

        "x-api-key":
          "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
      },
    };
    const res = await axios.post(
      `https://api.notatmrp.com/auth/retailer/logout`,
      null,
      config
    );

    // Remove the authentication token from the cookie
    Cookies.remove("notatmrp_seller");

    // Display success toast
    toast({
      title: "Logout Successful",
      description: "You have been successfully logged out.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  } catch (error) {
    // Handle error
    console.error("Error logging out:", error);
    // Display error toast
    toast({
      title: "Error",
      description:
        "An error occurred while logging out. Please try again later.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    // Perform any other error handling
  }
}
