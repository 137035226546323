import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";
import useStoreData from "../../service/useStoreData";

export default function AddCashback({ user, store }) {
  const [commissionPercentage, setCommissionPercentage] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (store && store.Commissionpercentage) {
      setCommissionPercentage(store.Commissionpercentage.toString());
    }
  }, [store]);

  const handleInputChange = (e) => {
    setCommissionPercentage(e.target.value);
  };

  const handleSubmit = async (e) => {
    const token = Cookies.get("notatmrp_seller");

    e.preventDefault();
    if (!user || !user.stores) {
      toast({
        title: "Error",
        description: "Store information is missing.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const storeId = user.stores[0];

    try {
      setLoading(true);
      await axios.patch(
        `https://api.notatmrp.com/stores/${storeId}`,
        { Commissionpercentage: Number(commissionPercentage) },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "Success",
        description: "Cashback updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setCommissionPercentage("");
      window.location.href = "/cashback";
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update cashback.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  //   if (!user || !user.stores) {
  //     return (
  //       <Box bg="white" p={6} rounded="md" boxShadow="lg" maxW="md" mt={6}>
  //         <Text fontSize="2xl" fontWeight="bold" color="purple.700" mb={4}>
  //           Error
  //         </Text>
  //         <Text color="red.500">Store information is missing.</Text>
  //       </Box>
  //     );
  //   }

  return (
    <div className="mt-[100px]">
      <Box bg="white" p={6} rounded="md" boxShadow="lg" maxW="md" mt={6}>
        <Text fontSize="2xl" fontWeight="bold" color="purple.700" mb={4}>
          Add Cashback
        </Text>
        <form onSubmit={handleSubmit}>
          <FormControl id="cashback" isRequired>
            <FormLabel>Cashback Percentage</FormLabel>
            <div className="flex items-center">
              <Input
                type="number"
                placeholder="Enter cashback number"
                value={commissionPercentage}
                onChange={handleInputChange}
                bg="white"
                focusBorderColor="purple.500"
              />
              <p className="pl-2 font-bold">%</p>
            </div>
          </FormControl>
          <Button
            type="submit"
            colorScheme="purple"
            mt={4}
            isLoading={loading}
            loadingText="Updating"
          >
            Add/Update Cashback
          </Button>
        </form>
      </Box>
    </div>
  );
}
