import React, { useEffect, useState } from "react";
import { startOfDay, isToday, isSameMonth, parseISO } from "date-fns";
import "./summary-box.scss";
import Box from "../box/Box";

// Function to filter today's orders
const filterTodayOrders = (orders) => {
  return orders.filter((order) => isToday(parseISO(order.createdAt)));
};

// Function to calculate summary values for today's orders
const calculateSummaryValues = (orders) => {
  const totalSales = orders.reduce(
    (sum, order) => sum + order.DiscountedPrice,
    0
  );
  const totalOrders = orders.length;
  const totalRevenue = totalSales; // Assuming total revenue is the same as total sales
  const totalVisits = orders.length; // Assuming each order represents a store visit

  return { totalSales, totalOrders, totalRevenue, totalVisits };
};

const SummaryBox = ({ orders = [] }) => {
  const [summaryValues, setSummaryValues] = useState({
    totalSales: 0,
    totalOrders: 0,
    totalRevenue: 0,
    totalVisits: 0,
  });

  useEffect(() => {
    if (orders.length > 0) {
      const todayOrders = filterTodayOrders(orders);
      const values = calculateSummaryValues(todayOrders);
      setSummaryValues(values);
    }
  }, [orders]);

  return (
    <div className="space-y-3">
      <div className="flex flex-col gap-5">
        <Box>
          <div className="summary-box">
            <div className="">
              <div className="summary-box__info__title">
                <div>Sales</div>
                <span>Total sales today</span>
              </div>
              <div className="summary-box__info__value">
                ₹{summaryValues.totalSales}
              </div>
            </div>
          </div>
        </Box>
        <Box>
          <div className="summary-box">
            <div className="">
              <div className="summary-box__info__title">
                <div>Orders</div>
                <span>Total orders today</span>
              </div>
              <div className="summary-box__info__value">
                {summaryValues.totalOrders}
              </div>
            </div>
          </div>
        </Box>
      </div>
      <div className="flex flex-col gap-5">
        <Box>
          <div className="summary-box">
            <div className="">
              <div className="summary-box__info__title">
                <div>Revenue</div>
                <span>Total revenue today</span>
              </div>
              <div className="summary-box__info__value">
                ₹{summaryValues.totalRevenue}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default SummaryBox;

export const SummaryBoxSpecial = ({ orders }) => {
  // Calculate the total revenue for the current month
  const currentMonth = new Date();

  const monthlyOrders = orders.filter((order) => {
    const orderDate = parseISO(order.createdAt);
    return isSameMonth(orderDate, currentMonth);
  });

  const totalMonthlyRevenue = monthlyOrders.reduce(
    (sum, order) => sum + order.DiscountedPrice,
    0
  );

  const chartOptions = {
    responsive: true,
    scales: {
      xAxis: {
        display: false,
      },
      yAxis: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <Box purple fullheight>
      <div className="summary-box-special">
        <div className="text-center">
          <div className="text-lg font-bold">Revenue</div>
          <span className="text-[10px]">This Month</span>
        </div>
        <div className="summary-box-special__value">₹{totalMonthlyRevenue}</div>
        <div className="summary-box-special__chart">
          {/* <Line options={chartOptions} data={chartData} width={`250px`} /> */}
        </div>
      </div>
    </Box>
  );
};
