import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  List,
  ListItem,
  Text,
  VStack,
  Heading,
  Divider,
  Flex,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";

const Step2 = ({ onNext, profileData, store }) => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    StoreAddress: "",
    StoreCoords: {
      longitude: null,
      latitude: null,
    },
  });
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Fetch existing store data if available
    if (store) {
      setFormData({
        StoreAddress: store.StoreAddress || "",
        StoreCoords: store.StoreCoords || {
          longitude: null,
          latitude: null,
        },
      });
    }
  }, [store]);

  // Fetch address suggestions based on user input
  const fetchSuggestions = async (input) => {
    try {
      const response = await axios.get(
        `https://api.notatmrp.com/api/places?input=${input}`,
        {
          headers: {
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      setSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      toast({
        title: "Error fetching suggestions.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle click on a suggestion
  const handleSuggestionClick = (suggestion) => {
    setFormData((prevData) => ({
      ...prevData,
      StoreAddress: suggestion.description,
      StoreCoords: {
        longitude: suggestion.longitude,
        latitude: suggestion.latitude,
      },
    }));
    setSuggestions([]);
  };

  // Handle input change and fetch suggestions
  const handleInputChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({ ...prevData, StoreAddress: value }));
    fetchSuggestions(value);
  };

  // Fetch current location and populate the form
  const fetchCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await axios.get(
            `https://api.notatmrp.com/api/current-location?latitude=${latitude}&longitude=${longitude}`,
            {
              headers: {
                "x-api-key":
                  "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
              },
            }
          );
          const locationDetails = response.data;
          setFormData((prevData) => ({
            ...prevData,
            StoreAddress: locationDetails.address,
            StoreCoords: {
              longitude,
              latitude,
            },
          }));
          toast({
            title: "Location fetched successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } catch (error) {
          console.error("Error fetching location:", error);
          toast({
            title: "Error fetching location.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    } else {
      toast({
        title: "Geolocation is not supported by this browser.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      // PATCH request to update store address and coordinates
      await axios.patch(
        `https://api.notatmrp.com/stores/${store._id}`, // Assuming store.id is the identifier for the store
        {
          StoreAddress: formData.StoreAddress,
          StoreCoords: formData.StoreCoords,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("notatmrp_seller")}`,

            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );

      toast({
        title: "Store address and coordinates updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      onNext(formData);
    } catch (error) {
      console.error("Error updating store:", error);
      toast({
        title: "Error updating store.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      maxW={{ base: "90%", md: "600px" }}
      mx="auto"
      py={10}
      px={{ base: 4, md: 8 }}
    >
      <Flex justifyContent="center" mb={4}>
        <Heading size="lg" color="purple.700">
          Step 2: Enter Store Address
        </Heading>
      </Flex>
      {/* <Flex justifyContent="center" mb={4}>
        <Heading size="md" color="purple.400">
          StoreId: {store._id}
        </Heading>
      </Flex> */}
      <Text textAlign="center" mb={8} color="gray.600" fontSize="lg">
        Please enter your store's address to continue.
      </Text>
      <VStack spacing={6} align="stretch">
        {/* Address Input */}
        <FormControl id="store-address" isRequired>
          <FormLabel>Store Address</FormLabel>
          <Input
            name="StoreAddress"
            placeholder="Enter your store address"
            value={formData.StoreAddress}
            onChange={handleInputChange}
          />
        </FormControl>

        {/* Suggestions List */}
        {suggestions.length > 0 && (
          <Box
            bg="white"
            borderWidth={1}
            borderColor="gray.200"
            borderRadius="md"
            boxShadow="md"
          >
            <List spacing={2} mt={2}>
              {suggestions.map((suggestion, index) => (
                <ListItem
                  key={index}
                  cursor="pointer"
                  onClick={() => handleSuggestionClick(suggestion)}
                  _hover={{ bg: "purple.50" }}
                  p={2}
                  borderRadius="md"
                >
                  {suggestion.description}
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Divider my={6} />

        {/* Current Location Button */}
        <Button colorScheme="purple" onClick={fetchCurrentLocation}>
          Use Current Location
        </Button>

        <Button colorScheme="purple" onClick={handleSubmit}>
          Next
        </Button>
      </VStack>
    </Box>
  );
};

export default Step2;
