import React, { useState } from "react";
const CustomerList = () => {
  const [customers, setCustomers] = useState([
    {
      id: 1,
      name: "Saksham Raghuvanshi",
      address: "123 Pathankot, Punjab",
      phoneNumber: "8239429311",
      customerSince: "2022-01-01",
      orders: [
        {
          orderId: 1,
          product: "Elegant Silk Item",
          price: "₹2,999",
          quantity: 2,
          total: "₹5,998",
        },
        {
          orderId: 2,
          product: "Cotton Handloom Item",
          price: "₹1,499",
          quantity: 1,
          total: "₹1,499",
        },
        {
          orderId: 6,
          product: "Silk Scarf",
          price: "₹1,199",
          quantity: 3,
          total: "₹3,597",
        },
        {
          orderId: 7,
          product: "Embroidered Blouse",
          price: "₹2,299",
          quantity: 1,
          total: "₹2,299",
        },
      ],
    },
    {
      id: 2,
      name: "Aditya Sharma",
      address: "Hno-15, Sector 22, Delhi",
      phoneNumber: "9983324203",
      customerSince: "2022-02-15",
      orders: [
        {
          orderId: 3,
          product: "Traditional Saree",
          price: "₹3,499",
          quantity: 1,
          total: "₹3,499",
        },
        {
          orderId: 4,
          product: "Handcrafted Kurti",
          price: "₹1,799",
          quantity: 3,
          total: "₹5,397",
        },
      ],
    },
    {
      id: 3,
      name: "Sharath Chandra",
      address: "456 Ahmedabad, Gujarat",
      phoneNumber: "7890123456",
      customerSince: "2022-03-10",
      orders: [
        {
          orderId: 5,
          product: "Handwoven Shawl",
          price: "₹2,199",
          quantity: 2,
          total: "₹4,398",
        },
        {
          orderId: 8,
          product: "Printed Cotton Dress",
          price: "₹2,499",
          quantity: 1,
          total: "₹2,499",
        },
      ],
    },
    {
      id: 4,
      name: "Prashika Sawant",
      address: "789 Jaipur, Rajasthan",
      phoneNumber: "8765432109",
      customerSince: "2022-04-20",
      orders: [
        {
          orderId: 9,
          product: "Leather Handbag",
          price: "₹3,299",
          quantity: 1,
          total: "₹3,299",
        },
        {
          orderId: 10,
          product: "Linen Shirt",
          price: "₹1,899",
          quantity: 2,
          total: "₹3,798",
        },
      ],
    },
    // Additional customers
    {
      id: 5,
      name: "Manisha Kapoor",
      address: "102 Mumbai, Maharashtra",
      phoneNumber: "9876543210",
      customerSince: "2022-05-05",
      orders: [
        {
          orderId: 11,
          product: "Summer Dress",
          price: "₹2,799",
          quantity: 1,
          total: "₹2,799",
        },
        {
          orderId: 12,
          product: "Casual Shoes",
          price: "₹1,299",
          quantity: 2,
          total: "₹2,598",
        },
      ],
    },
    {
      id: 6,
      name: "Vikram Singh",
      address: "22 Bangalore, Karnataka",
      phoneNumber: "7654321098",
      customerSince: "2022-06-15",
      orders: [
        {
          orderId: 13,
          product: "Sportswear Set",
          price: "₹3,599",
          quantity: 1,
          total: "₹3,599",
        },
        {
          orderId: 14,
          product: "Running Shoes",
          price: "₹1,799",
          quantity: 1,
          total: "₹1,799",
        },
      ],
    },
    {
      id: 7,
      name: "Anjali Gupta",
      address: "56 Chandigarh, Punjab",
      phoneNumber: "8765098231",
      customerSince: "2022-07-20",
      orders: [
        {
          orderId: 15,
          product: "Formal Shirt",
          price: "₹1,999",
          quantity: 1,
          total: "₹1,999",
        },
        {
          orderId: 16,
          product: "Leather Belt",
          price: "₹899",
          quantity: 2,
          total: "₹1,798",
        },
      ],
    },
    {
      id: 8,
      name: "Rajeev Mehta",
      address: "34 Chennai, Tamil Nadu",
      phoneNumber: "7897654321",
      customerSince: "2022-08-10",
      orders: [
        {
          orderId: 17,
          product: "Ethnic Kurta",
          price: "₹2,199",
          quantity: 1,
          total: "₹2,199",
        },
        {
          orderId: 18,
          product: "Jute Handbag",
          price: "₹1,499",
          quantity: 1,
          total: "₹1,499",
        },
      ],
    },
    {
      id: 9,
      name: "Megha Desai",
      address: "78 Pune, Maharashtra",
      phoneNumber: "9087654321",
      customerSince: "2022-09-25",
      orders: [
        {
          orderId: 19,
          product: "Home Decor Set",
          price: "₹4,999",
          quantity: 1,
          total: "₹4,999",
        },
      ],
    },
    {
      id: 10,
      name: "Neha Khanna",
      address: "90 Kolkata, West Bengal",
      phoneNumber: "8761234509",
      customerSince: "2022-10-12",
      orders: [
        {
          orderId: 21,
          product: "Party Wear Gown",
          price: "₹3,299",
          quantity: 1,
          total: "₹3,299",
        },
      ],
    },
  ]);

  const sortedCustomers = customers.slice().sort((a, b) => {
    const totalOrderAmountA = a.orders.reduce(
      (acc, order) =>
        acc + parseFloat(order.total.replace("₹", "").replace(",", "")),
      0
    );

    const totalOrderAmountB = b.orders.reduce(
      (acc, order) =>
        acc + parseFloat(order.total.replace("₹", "").replace(",", "")),
      0
    );

    return totalOrderAmountB - totalOrderAmountA;
  });

  return (
    <div className="bg-white text-black p-8 overflow-auto ">
      <h1 className="text-3xl font-bold mb-6">Customer List</h1>
      <table className="w-full border">
        <thead>
          <tr className="bg-purple-800 text-white">
            <th className="border p-2">Customer Name</th>
            <th className="border p-2">Total Orders</th>
            <th className="border p-2">Address</th>
            <th className="border p-2">Phone Number</th>
            <th className="border p-2">Customer Since</th>
          </tr>
        </thead>
        <tbody>
          {sortedCustomers.map((customer) => (
            <tr key={customer.id} className="bg-purple text-black">
              <td className="border p-2">{customer.name}</td>
              <td className="border p-2">{customer.orders.length}</td>
              <td className="border p-2">{customer.address}</td>
              <td className="border p-2">{customer.phoneNumber}</td>
              <td className="border p-2">{customer.customerSince}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerList;
