import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Box, Skeleton, SkeletonText, SkeletonCircle } from "@chakra-ui/react";
import DashboardWrapper, {
  DashboardWrapperMain,
  DashboardWrapperRight,
} from "../components/dashboard-wrapper/DashboardWrapper";
import axios from "axios";
import Cookies from "js-cookie";
import SummaryBox, {
  SummaryBoxSpecial,
} from "../components/summary-box/SummaryBox";
import { colors, data } from "../constants";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import OverallList from "../components/overall-list/OverallList";
import RevenueList from "../components/revenue-list/RevenueList";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = ({ store }) => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (store && store._id) {
      fetchOrders(store._id);
    }
  }, [store]);

  const fetchOrders = async (storeId) => {
    const token = Cookies.get("notatmrp_seller");

    try {
      const response = await axios.get(
        `https://api.notatmrp.com/auth/orders/store/${storeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      setOrders(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  return (
    <DashboardWrapper>
      <DashboardWrapperMain>
        <div className="row">
          <div className="col-8 col-md-12">
            <div className="row">
              <div className="col-6 col-md-6 col-sm-12 mb">
                {loading ? (
                  <Skeleton height="150px" />
                ) : (
                  <SummaryBox orders={orders} />
                )}
              </div>
            </div>
          </div>
          <div className="col-4 hide-md">
            {loading ? (
              <Skeleton height="150px" />
            ) : (
              <SummaryBoxSpecial orders={orders} />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Box>
              {loading ? (
                <Skeleton height="300px" />
              ) : (
                <RevenueByMonthsChart orders={orders} />
              )}
            </Box>
          </div>
        </div>
      </DashboardWrapperMain>
      <DashboardWrapperRight>
        <div className="title mb">Overall</div>
        <div className="mb">
          {loading ? (
            <SkeletonText mt="4" noOfLines={8} spacing="4" />
          ) : (
            <OverallList orders={orders} />
          )}
        </div>
      </DashboardWrapperRight>
    </DashboardWrapper>
  );
};

export default Dashboard;

const RevenueByMonthsChart = () => {
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      yAxes: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    elements: {
      bar: {
        backgroundColor: colors.orange,
        borderRadius: 20,
        borderSkipped: "bottom",
      },
    },
  };

  const chartData = {
    labels: data.revenueByMonths.labels,
    datasets: [
      {
        label: "Revenue",
        data: data.revenueByMonths.data,
      },
    ],
  };
  return (
    <>
      {/* <div className="title mb">Revenue by months</div>
      <div>
        <Bar options={chartOptions} data={chartData} height={`300px`} />
      </div> */}
    </>
  );
};
