// src/context/StoreContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import useRetialerData from "../service/useRetialerData";
import useStoreData from "../service/useStoreData";
// import useStoreData from "../service/useStoreData";
// import useRetialerData from "../service/useRetialerData";

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const { user, error: userError } = useRetialerData();
  const [storeId, setStoreId] = useState(user?.stores?.[0] || null);
  const { store, error: storeError } = useStoreData(storeId);

  useEffect(() => {
    // Update storeId when user data is loaded or if user changes stores
    if (user?.stores && !storeId) {
      setStoreId(user.stores[0]);
    }
  }, [user, storeId]);

  const changeStore = (newStoreId) => {
    setStoreId(newStoreId);
  };

  const hasSpecialStoreCategory =
    store && store.StoreCategory === "669a0b5b877265140df50307";

  return (
    <StoreContext.Provider
      value={{
        user,
        storeId,
        store,
        hasSpecialStoreCategory,
        changeStore,
        error: userError || storeError,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export const useStoreContext = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useStoreContext must be used within a StoreProvider");
  }
  return context;
};
