import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import logo from "../../assets/images/NOTATMRP.png";
import { useToast } from "@chakra-ui/react";

export default function TopPart() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);

  const toast = useToast();
  useEffect(() => {
    let timerId;
    if (resendTimer > 0) {
      timerId = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }

    return () => clearTimeout(timerId);
  }, [resendTimer]);

  const sendOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.notatmrp.com/auth/retailer/send-otp`,
        { phoneNumber: `+91${phoneNumber}` },
        {
          headers: {
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "OTP Sent",
        description: "OTP has been sent to your mobile number.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      setOrderId(response.data.orderId);
      setIsModalOpen(true);
    } catch (error) {
      setError(
        "Failed to send OTP: " +
          (error.response?.data?.message || error.message)
      );
      toast({
        title: "Error",
        description: "Failed to send OTP.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    await sendOtp();
  };

  const verifyOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.notatmrp.com/auth/retailer/verify-otp`,
        { phoneNumber: `+91${phoneNumber}`, orderId, otp },
        {
          headers: {
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "OTP Verified",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      if (response.data.token) {
        Cookies.set("notatmrp_seller", response.data.token, { expires: 30 });
        setIsModalOpen(false);
        if (response.data.isNewUser) {
          window.location.href = "/completeprofile";
        } else {
          window.location.href = "/";
        }
      }
    } catch (error) {
      setError(
        "Failed to verify OTP: " +
          (error.response?.data?.message || error.message)
      );
      toast({
        title: "Error",
        description: "Failed to verify OTP.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    await verifyOtp();
  };

  const resendOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.notatmrp.com/auth/retailer/resend-otp`,
        { orderId },
        {
          headers: {
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "OTP Resent",
        description: "OTP has been resent to your mobile number.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setResendTimer(60);
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to resend OTP.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <section className="max-w-screen-xl mx-auto py-2">
      <div className="md:flex mx-5">
        <div className="md:w-1/2 mt-[50px]">
          <img src={logo} alt="NOTATMRP LOGO" className="w-[250px]" />
          <h1 className="text-[25px] font-bold">
            India's Local Shopping Platform
          </h1>
          <h2 className="mt-3 text-[18px] font-serif">
            Connect with Customers and Increase Sales with NOT@MRP
          </h2>
        </div>
        <div className="md:w-1/2 flex flex-col mt-[100px] justify-center items-center">
          <p className="text-[12px]">
            Enter Your Mobile Number to get your store listed on NOT@MRP
          </p>
          <div className="mt-2">
            <form onSubmit={handlePhoneSubmit} className="">
              <input
                type="tel"
                className=" rounded-[20px] border  border-purple-900 py-3 px-4 mb-3 sm:mb-0 sm:mr-3 w-full sm:w-auto text-gray-700 leading-tight focus:outline-none  focus:border-purple-500"
                placeholder="Enter your mobile number"
                aria-label="Enter your mobile number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              <button
                className="bg-purple-500 rounded-[20px] hover:bg-purple-800 text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={loading}
              >
                {loading ? "Sending..." : "Continue"}
              </button>
            </form>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-lg font-bold mb-4">Verify OTP</h2>
            <form onSubmit={handleOtpSubmit}>
              <input
                type="text"
                className="appearance-none border rounded-[20px] border-purple-500 py-3 px-4 mb-3 w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-800"
                placeholder="Enter OTP"
                aria-label="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              <button
                className="bg-purple-500 rounded-[20px] hover:bg-purple-800 text-white font-bold py-3 px-6 focus:outline-none focus:shadow-outline w-full"
                type="submit"
                disabled={loading}
              >
                {loading ? "Verifying..." : "Verify OTP"}
              </button>
            </form>
            {resendTimer > 0 ? (
              <p className="text-sm text-gray-500 mt-3">
                Resend OTP in {resendTimer} seconds
              </p>
            ) : (
              <button
                className="text-sm text-purple-500 mt-3 hover:underline"
                onClick={resendOtp}
                disabled={loading}
              >
                Resend OTP
              </button>
            )}

            <p className="text-[10px] text-center mt-5">
              By clicking 'Send OTP' you accept our Privacy policy
            </p>
          </div>
        </div>
      )}
    </section>
  );
}
