import { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useToast,
  VStack,
  HStack,
  IconButton,
  Checkbox,
  Select,
  Heading,
  Image,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import Cookies from "js-cookie";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import ReactQuill styles

function AddressPart({
  formData,
  handleChange,
  handleCoordsChange,
  suggestions,
  handleSuggestionClick,
  fetchCurrentLocation,
}) {
  return <VStack spacing={4}>{/* ...existing AddressPart code... */}</VStack>;
}

function ImagesPart({ files, handleFileChange, existingImages }) {
  return (
    <VStack spacing={4}>
      <FormControl>
        <FormLabel>Store Logo</FormLabel>
        {existingImages.logourl && (
          <Image
            src={existingImages.logourl}
            alt="Store Logo"
            boxSize="100px"
          />
        )}
        <Input
          type="file"
          name="logourl"
          onChange={handleFileChange}
          accept="image/*"
        />
      </FormControl>

      <FormControl>
        <FormLabel>Store Interior Images (Max 5)</FormLabel>
        {existingImages.interiorimageurl &&
          existingImages.interiorimageurl.map((url, index) => (
            <Image
              key={index}
              src={url}
              alt={`Interior ${index + 1}`}
              boxSize="100px"
            />
          ))}
        <Input
          type="file"
          name="interiorimageurl"
          onChange={handleFileChange}
          accept="image/*"
          multiple
        />
      </FormControl>

      <FormControl>
        <FormLabel>Thumbnail</FormLabel>
        {existingImages.thumbnailurl && (
          <Image
            src={existingImages.thumbnailurl}
            alt="Thumbnail"
            boxSize="100px"
          />
        )}
        <Input
          type="file"
          name="thumbnailurl"
          onChange={handleFileChange}
          accept="image/*"
        />
      </FormControl>
    </VStack>
  );
}

function ImportantDetailsPart({
  formData,
  handleChange,
  handleDescriptionChange,
  handleBusinessHoursChange,
  socialHandle,
  setSocialHandle,
  handleAddSocialHandle,
  handleRemoveSocialHandle,
  categories,
}) {
  return (
    <VStack spacing={4}>
      <FormControl>
        <FormLabel>Store Name</FormLabel>
        <Input
          name="StoreName"
          value={formData.StoreName}
          onChange={handleChange}
          placeholder="Enter store name"
          isRequired
        />
      </FormControl>

      {/* <FormControl>
        <FormLabel>Store Category</FormLabel>
        <Select
          name="StoreCategory"
          value={formData.StoreCategory}
          onChange={handleChange}
          placeholder="Select category"
        >
          {categories.map((category) => (
            <option
              key={category._id}
              value={category._id}
              className="text-purple-800"
            >
              {category.categoryName}
            </option>
          ))}
        </Select>
      </FormControl> */}

      <FormControl>
        <FormLabel>About</FormLabel>
        <Input
          name="About"
          value={formData.About}
          onChange={handleChange}
          placeholder="Enter store description"
          isRequired
        />
      </FormControl>

      <FormControl>
        <FormLabel>Description</FormLabel>
        <ReactQuill
          theme="snow"
          value={formData.Description}
          onChange={handleDescriptionChange}
          placeholder="Enter store description"
        />
      </FormControl>

      <FormControl id="StoreContact" mt={4}>
        <FormLabel>Store Contact</FormLabel>
        <Input
          name="StoreContact"
          value={formData.StoreContact}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Social Handles</FormLabel>
        <HStack>
          <Input
            value={socialHandle}
            onChange={(e) => setSocialHandle(e.target.value)}
            placeholder="Add social handle"
          />
          <Button onClick={handleAddSocialHandle}>Add</Button>
        </HStack>
        {formData.Social_handles.map((handle, index) => (
          <HStack key={index} mt={2}>
            <Text>{handle}</Text>
            <IconButton
              icon={<FaTrash />}
              size="sm"
              onClick={() => handleRemoveSocialHandle(index)}
            />
          </HStack>
        ))}
      </FormControl>
    </VStack>
  );
}

export default function UpdateStore({ store }) {
  const toast = useToast();
  const [formData, setFormData] = useState({
    StoreName: "",
    StoreCategory: "",
    About: "",
    Description: "",
    TermsAndConditions: "",
    StoreContact: "",
    StoreCity: "",
    webURL: "",
    GST_Number: "",
    StoreAddress: "",
    StoreCoords: {
      latitude: "",
      longitude: "",
    },
    GoogleMapsURL: "",
    Business_Hours: [
      { day: "Monday", openTime: "", closeTime: "", closed: false },
      { day: "Tuesday", openTime: "", closeTime: "", closed: false },
      { day: "Wednesday", openTime: "", closeTime: "", closed: false },
      { day: "Thursday", openTime: "", closeTime: "", closed: false },
      { day: "Friday", openTime: "", closeTime: "", closed: false },
      { day: "Saturday", openTime: "", closeTime: "", closed: false },
      { day: "Sunday", openTime: "", closeTime: "", closed: false },
    ],
    Social_handles: [],
  });
  const [suggestions, setSuggestions] = useState([]);
  const [files, setFiles] = useState({});
  const [existingImages, setExistingImages] = useState({
    logourl: "",
    interiorimageurl: [],
    thumbnailurl: "",
  });
  const [socialHandle, setSocialHandle] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchStoreData();
    fetchCategories();
  }, [store]);

  const fetchStoreData = async () => {
    const token = Cookies.get("notatmrp_seller");

    try {
      const response = await axios.get(
        `https://api.notatmrp.com/stores/${store._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      const data = response.data;
      setFormData({
        StoreName: data.StoreName,
        StoreCategory: data.StoreCategory,
        About: data.About,
        Description: data.Description,
        TermsAndConditions: data.TermsAndConditions,
        StoreContact: data.StoreContact,
        StoreCity: data.StoreCity,
        webURL: data.webURL,
        GST_Number: data.GST_Number,
        StoreAddress: data.StoreAddress,
        StoreCoords: data.StoreCoords,
        GoogleMapsURL: data.GoogleMapsURL,
        Business_Hours: data.Business_Hours,
        Social_handles: data.Social_handles,
      });
      setExistingImages({
        logourl: data.logourl,
        interiorimageurl: data.interiorimageurl,
        thumbnailurl: data.thumbnailurl,
      });
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  const fetchCategories = async () => {
    const token = Cookies.get("notatmrp_seller");

    try {
      const response = await axios.get(
        `https://api.notatmrp.com/category/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDescriptionChange = (value) => {
    setFormData((prev) => ({ ...prev, Description: value }));
  };

  const handleCoordsChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      StoreCoords: { ...prev.StoreCoords, [name]: value },
    }));
  };

  const handleBusinessHoursChange = (index, field, value) => {
    const updatedHours = formData.Business_Hours.map((hour, i) =>
      i === index ? { ...hour, [field]: value } : hour
    );
    setFormData((prev) => ({ ...prev, Business_Hours: updatedHours }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFiles((prev) => ({ ...prev, [name]: files }));
  };

  const handleAddSocialHandle = () => {
    if (socialHandle.trim()) {
      setFormData((prev) => ({
        ...prev,
        Social_handles: [...prev.Social_handles, socialHandle.trim()],
      }));
      setSocialHandle("");
    }
  };

  const handleRemoveSocialHandle = (index) => {
    const updatedHandles = formData.Social_handles.filter(
      (_, i) => i !== index
    );
    setFormData((prev) => ({ ...prev, Social_handles: updatedHandles }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await uploadFiles();
      const token = Cookies.get("notatmrp_seller");

      const response = await axios.put(
        `https://api.notatmrp.com/stores/${store._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      if (response.status === 200) {
        toast({
          title: "Store updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Failed to update store",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating store:", error);
      toast({
        title: "Failed to update store",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const uploadFiles = async () => {
    const upload = async (file, path) => {
      const fileRef = ref(storage, path);
      await uploadBytes(fileRef, file);
      return await getDownloadURL(fileRef);
    };

    if (files.logourl && files.logourl[0]) {
      const logoUrl = await upload(files.logourl[0], `logos/${store._id}`);
      setFormData((prev) => ({ ...prev, logourl: logoUrl }));
    }

    if (files.storeInteriorImages) {
      const interiorUrls = [];
      for (let file of files.storeInteriorImages) {
        const url = await upload(
          file,
          `storeInteriorImages/${store._id}/${file.name}`
        );
        interiorUrls.push(url);
      }
      setFormData((prev) => ({ ...prev, storeInteriorImages: interiorUrls }));
    }

    if (files.interiorimageurl && files.interiorimageurl[0]) {
      const thumbnailUrl = await upload(
        files.interiorimageurl[0],
        `thumbnails/${store._id}`
      );
      setFormData((prev) => ({ ...prev, thumbnailurl: thumbnailUrl }));
    }
  };

  const fetchLocationSuggestions = async (input) => {
    try {
      const response = await axios.get(
        `https://api.notatmrp.com/api/places?input=${input}`,
        {
          headers: {
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      setSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData((prevData) => ({
      ...prevData,
      StoreAddress: suggestion.description,
      StoreCoords: {
        longitude: suggestion.longitude,
        latitude: suggestion.latitude,
      },
      GoogleMapsURL: `https://www.google.com/maps/?q=${suggestion.latitude},${suggestion.longitude}`,
    }));
    setSuggestions([]);
  };

  const fetchCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await axios.get(
            `https://api.notatmrp.com/api/current-location?latitude=${latitude}&longitude=${longitude}`,
            {
              headers: {
                "x-api-key":
                  "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
              },
            }
          );
          const locationDetails = response.data;
          setFormData((prevData) => ({
            ...prevData,
            StoreAddress: locationDetails.address,
            StoreCoords: {
              longitude: locationDetails.longitude,
              latitude: locationDetails.latitude,
            },
            GoogleMapsURL: `https://www.google.com/maps/?q=${locationDetails.latitude},${locationDetails.longitude}`,
          }));
        } catch (error) {
          console.error("Error fetching current location:", error);
        }
      });
    }
  };

  return (
    <Box p={5}>
      <Heading as="h1" size="lg" mb={5}>
        Edit Store Details
      </Heading>
      <form onSubmit={handleSubmit}>
        <Stack spacing={8}>
          <ImportantDetailsPart
            formData={formData}
            handleChange={handleChange}
            handleDescriptionChange={handleDescriptionChange}
            handleBusinessHoursChange={handleBusinessHoursChange}
            socialHandle={socialHandle}
            setSocialHandle={setSocialHandle}
            handleAddSocialHandle={handleAddSocialHandle}
            handleRemoveSocialHandle={handleRemoveSocialHandle}
            categories={categories}
          />
          <ImagesPart
            files={files}
            handleFileChange={handleFileChange}
            existingImages={existingImages}
          />
        </Stack>
        <Button type="submit" colorScheme="purple" mt={5}>
          Update Store
        </Button>
      </form>
    </Box>
  );
}
