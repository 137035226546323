import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Text,
  useToast,
  VStack,
  HStack,
  IconButton,
  Checkbox,
  Select,
  Heading,
  Input,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import { FaTrash } from "react-icons/fa";
import Cookies from "js-cookie";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";

export default function AddStores({ onComplete, user }) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState({
    logo: false,
    ownerAdhaarCard: false,
    electricityBill: false,
    gstCertificate: false,
    signBoard: false,
    storeInteriorImages: false,
    thumbnail: false,
  });
  const [uploadProgress, setUploadProgress] = useState({
    logo: "",
    ownerAdhaarCard: "",
    electricityBill: "",
    gstCertificate: "",
    signBoard: "",
    storeInteriorImages: [],
    thumbnail: "",
  });

  const [formData, setFormData] = useState({
    StoreName: "",
    StoreCategory: "",
    About: "",
    TermsAndConditions: "",
    StoreAddress: "",
    StoreContact: "",
    StoreCity: "",
    StoreCoords: {
      longitude: "",
      latitude: "",
    },
    GoogleMapsURL: "",
    webURL: "",
    GST_Number: "",
    Business_Hours: [
      { day: "Monday", openTime: "", closeTime: "", closed: false },
      { day: "Tuesday", openTime: "", closeTime: "", closed: false },
      { day: "Wednesday", openTime: "", closeTime: "", closed: false },
      { day: "Thursday", openTime: "", closeTime: "", closed: false },
      { day: "Friday", openTime: "", closeTime: "", closed: false },
      { day: "Saturday", openTime: "", closeTime: "", closed: false },
      { day: "Sunday", openTime: "", closeTime: "", closed: false },
    ],
    Social_handles: [],
    logourl: "",
    ownerAdhaarCardurl: "",
    electricityBillurl: "",
    gstCertificateurl: "",
    signBoardurl: "",
    interiorimageurl: [],
    thumbnailurl: "",
    Description: "",
  });

  const [categories, setCategories] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [socialHandle, setSocialHandle] = useState("");
  const [files, setFiles] = useState({
    logo: null,
    ownerAdhaarCard: null,
    electricityBill: null,
    gstCertificate: null,
    signBoard: null,
    storeInteriorImages: [],
    thumbnail: null,
  });

  const [copyFromDay, setCopyFromDay] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://api.notatmrp.com/category/all/",
          {
            headers: {
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
            },
          }
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "StoreAddress" && value.length > 2) {
      fetchSuggestions(value);
    }
  };

  const handleCoordsChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      StoreCoords: {
        ...prevData.StoreCoords,
        [name]: value,
      },
    }));
  };

  const handleBusinessHoursChange = (index, field, value) => {
    const newBusinessHours = [...formData.Business_Hours];
    newBusinessHours[index][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      Business_Hours: newBusinessHours,
    }));
  };

  const handleCopyBusinessHours = (index) => {
    const dayToCopy = formData.Business_Hours.find(
      (hour) => hour.day === copyFromDay
    );
    if (dayToCopy) {
      const newBusinessHours = [...formData.Business_Hours];
      newBusinessHours[index] = {
        ...newBusinessHours[index],
        openTime: dayToCopy.openTime,
        closeTime: dayToCopy.closeTime,
        closed: dayToCopy.closed,
      };
      setFormData((prevData) => ({
        ...prevData,
        Business_Hours: newBusinessHours,
      }));
    } else {
      toast({
        title: "Error",
        description: "Please select a valid day to copy from.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddSocialHandle = () => {
    if (socialHandle) {
      setFormData((prevData) => ({
        ...prevData,
        Social_handles: [...prevData.Social_handles, socialHandle],
      }));
      setSocialHandle("");
    }
  };

  const handleRemoveSocialHandle = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      Social_handles: prevData.Social_handles.filter((_, i) => i !== index),
    }));
  };

  const fetchSuggestions = async (input) => {
    try {
      const response = await axios.get(
        `https://api.notatmrp.com/api/places?input=${input}`,
        {
          headers: {
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      setSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData((prevData) => ({
      ...prevData,
      StoreAddress: suggestion.description,
      StoreCoords: {
        longitude: suggestion.longitude,
        latitude: suggestion.latitude,
      },
      GoogleMapsURL: `https://www.google.com/maps/?q=${suggestion.latitude},${suggestion.longitude}`,
    }));
    setSuggestions([]);
  };

  const fetchCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await axios.get(
            `https://api.notatmrp.com/api/current-location?latitude=${latitude}&longitude=${longitude}`,
            {
              headers: {
                "x-api-key":
                  "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
              },
            }
          );
          const locationDetails = response.data;
          setFormData((prevData) => ({
            ...prevData,
            StoreAddress: locationDetails.address,
            StoreCoords: {
              longitude: locationDetails.longitude,
              latitude: locationDetails.latitude,
            },
            GoogleMapsURL: `https://www.google.com/maps/?q=${locationDetails.latitude},${locationDetails.longitude}`,
          }));
        } catch (error) {
          console.error("Error fetching current location:", error);
        }
      });
    }
  };

  const onDrop = useCallback((acceptedFiles, name) => {
    setUploading((prevUploading) => ({ ...prevUploading, [name]: true }));

    const uploadPromises = acceptedFiles.map((file) => {
      const storageRef = ref(storage, `${name}/${file.name}`);
      return uploadBytes(storageRef, file)
        .then(() => getDownloadURL(storageRef))
        .then((url) => {
          setFormData((prevData) => ({
            ...prevData,
            [`${name}url`]:
              name === "storeInteriorImages"
                ? [...prevData.interiorimageurl, url]
                : url,
          }));
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [name]:
              name === "storeInteriorImages"
                ? [
                    ...prevProgress.storeInteriorImages,
                    `${file.name} 100% uploaded`,
                  ]
                : `${file.name} 100% uploaded`,
          }));
        });
    });

    Promise.all(uploadPromises)
      .then(() => {
        setUploading((prevUploading) => ({ ...prevUploading, [name]: false }));
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        setUploading((prevUploading) => ({ ...prevUploading, [name]: false }));
      });
  }, []);

  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } =
    useDropzone({
      onDrop: (acceptedFiles) => onDrop(acceptedFiles, "logo"),
      accept: "image/*",
    });

  const {
    getRootProps: getOwnerAdhaarCardRootProps,
    getInputProps: getOwnerAdhaarCardInputProps,
  } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "ownerAdhaarCard"),
    accept: "image/*,application/pdf",
  });

  const {
    getRootProps: getElectricityBillRootProps,
    getInputProps: getElectricityBillInputProps,
  } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "electricityBill"),
    accept: "image/*,application/pdf",
  });

  const {
    getRootProps: getGstCertificateRootProps,
    getInputProps: getGstCertificateInputProps,
  } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "gstCertificate"),
    accept: "image/*,application/pdf",
  });

  const {
    getRootProps: getSignBoardRootProps,
    getInputProps: getSignBoardInputProps,
  } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "signBoard"),
    accept: "image/*,application/pdf",
  });

  const {
    getRootProps: getStoreInteriorImagesRootProps,
    getInputProps: getStoreInteriorImagesInputProps,
  } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "storeInteriorImages"),
    accept: "image/*",
  });

  const {
    getRootProps: getThumbnailRootProps,
    getInputProps: getThumbnailInputProps,
  } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "thumbnail"),
    accept: "image/*",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = Cookies.get("notatmrp_seller");
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://api.notatmrp.com/stores",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );

      if (response.status === 201) {
        toast({
          title: "Success",
          description: "Store added successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        if (onComplete) {
          onComplete();
          window.location.href = "/";
        }
      } else {
        toast({
          title: "Error",
          description: "Something went wrong while adding the store",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error adding store:", error);
      toast({
        title: "Error",
        description: "An error occurred while adding the store",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Navbar user={user} />
      <div className="mt-10">
        <Box
          minH="100vh"
          bg="white"
          p={4}
          color="purple.800"
          maxWidth="800px"
          mx="auto"
        >
          <VStack spacing={8} as="form" onSubmit={handleSubmit}>
            <Heading fontWeight="bold" color="purple.600">
              Add Store
            </Heading>

            {/* Store Information */}
            <Box w="full">
              <Heading size="md" color="purple.600">
                Store Information
              </Heading>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Store Name</FormLabel>
                  <Input
                    name="StoreName"
                    value={formData.StoreName}
                    onChange={handleChange}
                    placeholder="Enter store name"
                    isRequired
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Store Category</FormLabel>
                  <Select
                    name="StoreCategory"
                    value={formData.StoreCategory}
                    onChange={handleChange}
                    placeholder="Select category"
                  >
                    {categories
                      .sort((a, b) =>
                        a.categoryName.localeCompare(b.categoryName)
                      )
                      .map((category) => (
                        <option
                          key={category._id}
                          value={category._id}
                          className="text-purple-800"
                        >
                          {category.categoryName}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>About</FormLabel>
                  <Input
                    name="About"
                    value={formData.About}
                    onChange={handleChange}
                    placeholder="Enter store description"
                    isRequired
                  />
                </FormControl>
              </VStack>
            </Box>

            {/* Address */}
            <Box w="full">
              <Heading size="md" color="purple.600">
                Address
              </Heading>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Store Address</FormLabel>
                  <Input
                    name="StoreAddress"
                    value={formData.StoreAddress}
                    onChange={handleChange}
                    placeholder="Enter store address"
                    isRequired
                  />
                  {suggestions.map((suggestion) => (
                    <Box
                      key={suggestion.place_id}
                      onClick={() => handleSuggestionClick(suggestion)}
                      cursor="pointer"
                      bg="purple.50"
                      p={2}
                      my={1}
                      borderRadius="md"
                    >
                      {suggestion.description}
                    </Box>
                  ))}
                  <Button mt={2} onClick={fetchCurrentLocation}>
                    Use Current Location
                  </Button>
                </FormControl>

                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} w="full">
                  <FormControl isRequired>
                    <FormLabel>Longitude</FormLabel>
                    <Input
                      name="longitude"
                      value={formData.StoreCoords.longitude}
                      onChange={handleCoordsChange}
                      placeholder="Enter longitude"
                      isRequired
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Latitude</FormLabel>
                    <Input
                      name="latitude"
                      value={formData.StoreCoords.latitude}
                      onChange={handleCoordsChange}
                      placeholder="Enter latitude"
                      isRequired
                    />
                  </FormControl>
                </SimpleGrid>

                <FormControl>
                  <FormLabel>Google Maps URL</FormLabel>
                  <Input
                    name="GoogleMapsURL"
                    value={formData.GoogleMapsURL}
                    onChange={handleChange}
                    placeholder="Generated Google Maps URL"
                    isReadOnly
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Store Contact</FormLabel>
                  <Input
                    name="StoreContact"
                    value={formData.StoreContact}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>City</FormLabel>
                  <Input
                    name="StoreCity"
                    value={formData.StoreCity}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Web URL</FormLabel>
                  <Input
                    name="webURL"
                    value={formData.webURL}
                    onChange={handleChange}
                    placeholder="Enter store web URL"
                  />
                </FormControl>
              </VStack>
            </Box>

            {/* Business Hours */}
            <Box w="full">
              <Heading size="md" color="purple.600">
                Business Hours
              </Heading>
              <VStack spacing={4}>
                <VStack spacing={4} align="start" w="full">
                  {formData.Business_Hours.map((hour, index) => (
                    <SimpleGrid
                      columns={{ base: 1, md: 2 }}
                      spacing={4}
                      w="full"
                      key={index}
                    >
                      <Text>{hour.day}</Text>
                      <SimpleGrid columns={3} spacing={2} w="full">
                        <Input
                          type="time"
                          placeholder="Open Time"
                          value={hour.openTime}
                          onChange={(e) =>
                            handleBusinessHoursChange(
                              index,
                              "openTime",
                              e.target.value
                            )
                          }
                          disabled={hour.closed}
                        />
                        <Input
                          type="time"
                          placeholder="Close Time"
                          value={hour.closeTime}
                          onChange={(e) =>
                            handleBusinessHoursChange(
                              index,
                              "closeTime",
                              e.target.value
                            )
                          }
                          disabled={hour.closed}
                        />
                        <Checkbox
                          isChecked={hour.closed}
                          onChange={(e) =>
                            handleBusinessHoursChange(
                              index,
                              "closed",
                              e.target.checked
                            )
                          }
                        >
                          Closed
                        </Checkbox>
                      </SimpleGrid>
                      <HStack spacing={2}>
                        <Select
                          placeholder="Copy from day"
                          value={copyFromDay}
                          onChange={(e) => setCopyFromDay(e.target.value)}
                        >
                          {formData.Business_Hours.map((option) => (
                            <option key={option.day} value={option.day}>
                              {option.day}
                            </option>
                          ))}
                        </Select>
                        <Button onClick={() => handleCopyBusinessHours(index)}>
                          Copy
                        </Button>
                      </HStack>
                    </SimpleGrid>
                  ))}
                </VStack>
              </VStack>
            </Box>

            {/* Social Handles */}
            <Box w="full">
              <Heading size="md" color="purple.600">
                Social Handles
              </Heading>
              <VStack spacing={4}>
                <HStack>
                  <Input
                    value={socialHandle}
                    onChange={(e) => setSocialHandle(e.target.value)}
                    placeholder="Add social handle"
                  />
                  <Button onClick={handleAddSocialHandle}>Add</Button>
                </HStack>
                {formData.Social_handles.map((handle, index) => (
                  <HStack key={index} mt={2}>
                    <Text>{handle}</Text>
                    <IconButton
                      icon={<FaTrash />}
                      size="sm"
                      onClick={() => handleRemoveSocialHandle(index)}
                    />
                  </HStack>
                ))}
              </VStack>
            </Box>

            {/* Document Numbers */}
            <Box w="full">
              <Heading size="md" color="purple.600">
                Document Numbers
              </Heading>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>GST Number</FormLabel>
                  <Input
                    name="GST_Number"
                    value={formData.GST_Number}
                    onChange={handleChange}
                    placeholder="Enter GST number"
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>PAN Number (owner/Business)</FormLabel>
                  <Input
                    name="TermsAndConditions"
                    value={formData.TermsAndConditions}
                    onChange={handleChange}
                    placeholder="Enter store PAN Number"
                    isRequired
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Fssai Number</FormLabel>
                  <Input
                    name="StoreURL"
                    value={formData.StoreURL}
                    onChange={handleChange}
                    placeholder="Enter Fssai Number"
                  />
                </FormControl>
              </VStack>
            </Box>

            {/* Documents */}
            <Box w="full">
              <Heading size="md" color="purple.600">
                Documents
              </Heading>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Store Logo</FormLabel>
                  <Box
                    p={4}
                    borderWidth={2}
                    borderStyle="dashed"
                    borderColor="gray.300"
                    rounded="md"
                    textAlign="center"
                    {...getLogoRootProps()}
                  >
                    <input {...getLogoInputProps()} />
                    {uploading.logo ? (
                      <Spinner size="xl" />
                    ) : (
                      <Text>
                        Drag 'n' drop the store logo here, or click to select
                        file
                      </Text>
                    )}
                    {uploadProgress.logo && <Text>{uploadProgress.logo}</Text>}
                  </Box>
                </FormControl>

                <FormControl>
                  <FormLabel>Owner Adhaar Card</FormLabel>
                  <Box
                    p={4}
                    borderWidth={2}
                    borderStyle="dashed"
                    borderColor="gray.300"
                    rounded="md"
                    textAlign="center"
                    {...getOwnerAdhaarCardRootProps()}
                  >
                    <input {...getOwnerAdhaarCardInputProps()} />
                    {uploading.ownerAdhaarCard ? (
                      <Spinner size="xl" />
                    ) : (
                      <Text>
                        Drag 'n' drop the owner Adhaar Card here, or click to
                        select file
                      </Text>
                    )}
                    {uploadProgress.ownerAdhaarCard && (
                      <Text>{uploadProgress.ownerAdhaarCard}</Text>
                    )}
                  </Box>
                </FormControl>

                <FormControl>
                  <FormLabel>PAN Card</FormLabel>
                  <Box
                    p={4}
                    borderWidth={2}
                    borderStyle="dashed"
                    borderColor="gray.300"
                    rounded="md"
                    textAlign="center"
                    {...getElectricityBillRootProps()}
                  >
                    <input {...getElectricityBillInputProps()} />
                    {uploading.electricityBill ? (
                      <Spinner size="xl" />
                    ) : (
                      <Text>
                        Drag 'n' drop the PAN Card here, or click to select file
                      </Text>
                    )}
                    {uploadProgress.electricityBill && (
                      <Text>{uploadProgress.electricityBill}</Text>
                    )}
                  </Box>
                </FormControl>

                <FormControl>
                  <FormLabel>GST Certificate</FormLabel>
                  <Box
                    p={4}
                    borderWidth={2}
                    borderStyle="dashed"
                    borderColor="gray.300"
                    rounded="md"
                    textAlign="center"
                    {...getGstCertificateRootProps()}
                  >
                    <input {...getGstCertificateInputProps()} />
                    {uploading.gstCertificate ? (
                      <Spinner size="xl" />
                    ) : (
                      <Text>
                        Drag 'n' drop the GST Certificate here, or click to
                        select file
                      </Text>
                    )}
                    {uploadProgress.gstCertificate && (
                      <Text>{uploadProgress.gstCertificate}</Text>
                    )}
                  </Box>
                </FormControl>

                <FormControl>
                  <FormLabel>Fssai Certificate</FormLabel>
                  <Box
                    p={4}
                    borderWidth={2}
                    borderStyle="dashed"
                    borderColor="gray.300"
                    rounded="md"
                    textAlign="center"
                    {...getSignBoardRootProps()}
                  >
                    <input {...getSignBoardInputProps()} />
                    {uploading.signBoard ? (
                      <Spinner size="xl" />
                    ) : (
                      <Text>
                        Drag 'n' drop the Fssai Certificate here, or click to
                        select file
                      </Text>
                    )}
                    {uploadProgress.signBoard && (
                      <Text>{uploadProgress.signBoard}</Text>
                    )}
                  </Box>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Store Interior Images (Max 5)</FormLabel>
                  <Box
                    p={4}
                    borderWidth={2}
                    borderStyle="dashed"
                    borderColor="gray.300"
                    rounded="md"
                    textAlign="center"
                    {...getStoreInteriorImagesRootProps()}
                  >
                    <input {...getStoreInteriorImagesInputProps()} />
                    {uploading.storeInteriorImages ? (
                      <Spinner size="xl" />
                    ) : (
                      <Text>
                        Drag 'n' drop the store interior images here, or click
                        to select files
                      </Text>
                    )}
                    {uploadProgress.storeInteriorImages.map((file, index) => (
                      <Text key={index}>{file}</Text>
                    ))}
                  </Box>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Thumbnail Image</FormLabel>
                  <Box
                    p={4}
                    borderWidth={2}
                    borderStyle="dashed"
                    borderColor="gray.300"
                    rounded="md"
                    textAlign="center"
                    {...getThumbnailRootProps()}
                  >
                    <input {...getThumbnailInputProps()} />
                    {uploading.thumbnail ? (
                      <Spinner size="xl" />
                    ) : (
                      <Text>
                        Drag 'n' drop the thumbnail image here, or click to
                        select file
                      </Text>
                    )}
                    {uploadProgress.thumbnail && (
                      <Text>{uploadProgress.thumbnail}</Text>
                    )}
                  </Box>
                </FormControl>
              </VStack>
            </Box>

            <Text className="text-md">
              Onboarding Charge: ₹1000 + 18% GST Total: ₹1180
            </Text>
            <Button
              type="submit"
              colorScheme="purple"
              isLoading={isLoading}
              disabled={isLoading}
              w="full"
            >
              Add Store
            </Button>
          </VStack>
        </Box>
      </div>
    </>
  );
}
