import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";

const AddOffers = ({ store }) => {
  const [offers, setOffers] = useState([]);
  const [formData, setFormData] = useState({
    StoreID: "",
    OfferType: "",
    OfferPercentage: "",
    CouponCode: "",
    Title: "",
    Description: "",
    TermsandCondition: "",
    startDate: "",
    expiryDate: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [editOfferId, setEditOfferId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteOfferId, setDeleteOfferId] = useState(null);
  const cancelRef = React.useRef();
  const toast = useToast();

  useEffect(() => {
    if (store && store._id) {
      setFormData((prevData) => ({
        ...prevData,
        StoreID: store._id,
      }));
    }
    fetchOffers();
  }, [store]);

  const fetchOffers = async () => {
    try {
      const token = Cookies.get("notatmrp_seller");

      const storeId = store._id;
      const res = await axios.get(
        `https://api.notatmrp.com/offers/store/${storeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      setOffers(res.data);
      // console.log("offers", res.data);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = Cookies.get("notatmrp_seller");

      if (editMode) {
        console.log("OfferID", editOfferId);
        await axios.patch(
          `https://api.notatmrp.com/offers/${editOfferId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
            },
          }
        );
        toast({
          title: "Offer updated.",
          description: "The offer has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await axios.post("https://api.notatmrp.com/offers", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        });
        toast({
          title: "Offer added.",
          description: "The offer has been added successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      fetchOffers();
      setEditMode(false);
      setEditOfferId(null);
      setFormData({
        StoreID: store.id,
        OfferType: "",
        OfferPercentage: "",
        CouponCode: "",
        Title: "",
        Description: "",
        TermsandCondition: "",
        startDate: "",
        expiryDate: "",
      });
    } catch (error) {
      console.error("Error adding/updating offer:", error);
      toast({
        title: "Error",
        description: `An error occurred while ${
          editMode ? "updating" : "adding"
        } the offer.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (offer) => {
    setEditMode(true);
    setEditOfferId(offer._id);
    setFormData({
      StoreID: offer.StoreID,
      OfferType: offer.OfferType,
      OfferPercentage: offer.OfferPercentage,
      CouponCode: offer.CouponCode,
      Title: offer.Title,
      Description: offer.Description,
      TermsandCondition: offer.TermsandCondition,
      startDate: new Date(offer.startDate).toISOString().split("T")[0],
      expiryDate: new Date(offer.expiryDate).toISOString().split("T")[0],
    });
  };

  const handleDelete = async () => {
    try {
      const token = Cookies.get("notatmrp_seller");
      await axios.delete(`https://api.notatmrp.com/offers/${deleteOfferId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key":
            "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
        },
      });
      toast({
        title: "Offer deleted.",
        description: "The offer has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchOffers();
      setIsDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting offer:", error);
      toast({
        title: "Error",
        description: "An error occurred while deleting the offer.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteOfferId(id);
    setIsDeleteDialogOpen(true);
  };

  return (
    <Box bg="white" p={6} rounded="md" shadow="md">
      <Heading mb={6} color="purple.600">
        {editMode ? "Edit Offer" : "Add Offer"}
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="OfferType" isRequired>
            <FormLabel>Offer Type</FormLabel>
            <Select
              name="OfferType"
              value={formData.OfferType}
              onChange={handleInputChange}
            >
              <option value="">Select Offer Type</option>
              <option value="Coupon">Coupon Code</option>
              <option value="Deal">Deal</option>
            </Select>
          </FormControl>
          <FormControl id="OfferPercentage">
            <FormLabel>Offer Percentage</FormLabel>
            <Input
              type="number"
              name="OfferPercentage"
              value={formData.OfferPercentage}
              onChange={handleInputChange}
              min={0}
              max={100}
            />
          </FormControl>
          <FormControl id="CouponCode">
            <FormLabel>Coupon Code</FormLabel>
            <Input
              type="text"
              name="CouponCode"
              value={formData.CouponCode}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="Title" isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              type="text"
              name="Title"
              value={formData.Title}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="Description" isRequired>
            <FormLabel>Description</FormLabel>
            <Textarea
              name="Description"
              value={formData.Description}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="TermsandCondition">
            <FormLabel>Terms and Conditions</FormLabel>
            <Textarea
              name="TermsandCondition"
              value={formData.TermsandCondition}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="startDate" isRequired>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="expiryDate" isRequired>
            <FormLabel>Expiry Date</FormLabel>
            <Input
              type="date"
              name="expiryDate"
              value={formData.expiryDate}
              onChange={handleInputChange}
            />
          </FormControl>
          <Button type="submit" colorScheme="purple" width="full">
            {editMode ? "Update Offer" : "Add Offer"}
          </Button>
        </VStack>
      </form>
      <Box mt={10} className="overflow-auto">
        <Heading mb={6} color="purple.600">
          Offers List
        </Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              {/* <Th>Store ID</Th> */}
              <Th>Offer Type</Th>
              <Th>Offer Percentage</Th>
              <Th>Coupon Code</Th>
              <Th>Title</Th>
              <Th>Description</Th>
              <Th>Start Date</Th>
              <Th>Expiry Date</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {offers.map((offer) => (
              <Tr key={offer._id}>
                {/* <Td>{offer.StoreID}</Td> */}
                <Td>{offer.OfferType}</Td>
                <Td>{offer.OfferPercentage}</Td>
                <Td>{offer.CouponCode}</Td>
                <Td>{offer.Title}</Td>
                <Td>{offer.Description}</Td>
                <Td>{new Date(offer.startDate).toLocaleDateString()}</Td>
                <Td>{new Date(offer.expiryDate).toLocaleDateString()}</Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => handleEdit(offer)}
                    mr={2}
                  >
                    Edit
                  </Button>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => handleDeleteClick(offer._id)}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Offer
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this offer? This action cannot be
              undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setIsDeleteDialogOpen(false)}
              >
                No
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default AddOffers;
