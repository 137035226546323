import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Autoplay, Pagination } from "swiper/modules";

const categories = [
  { name: "Restaurants", image: "/images/cafe.png" },
  { name: "Fashion", image: "/images/clothing.png" },
  { name: "Grocery", image: "/images/Grocery.png" },
  { name: "Electronics", image: "/images/appliances.png" },
  { name: "Beauty", image: "/images/beauty.png" },
  { name: "Furniture", image: "/images/furniture.png" },
  { name: "Gym", image: "/images/gym.png" },
  { name: "Salon", image: "/images/salon.png" },
];

export default function CategoryPart() {
  return (
    <div className="max-w-screen-xl mx-auto mt-10 px-6">
      <h2 className="text-[30px] lg:text-[40px] font-bold text-center mb-5">
        Join the Business
      </h2>
      <Swiper
        spaceBetween={10}
        slidesPerView={2}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {categories.map((category, index) => (
          <SwiperSlide key={index} className="flex flex-col items-center">
            <img
              src={category.image}
              alt={category.name}
              className="w-48 h-48 object-contain rounded-lg shadow-md"
            />
            <h3 className="text-xl text-center font-semibold text-purple-600 mt-2">
              {category.name}
            </h3>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
