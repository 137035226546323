import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import logoim from "../../assets/images/logoim.png";
import logoimg from "../../assets/images/logoimg.png";
import sidebarNav from "../../configs/sidebarNav";
import { Logout } from "../../service/Logout";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { TbLogout2 } from "react-icons/tb";
import { IoClose } from "react-icons/io5";

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loadings, setloadings] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const closeSidebar = () => {
    document.querySelector(".main__content").style.transform =
      "scale(1) translateX(0)";
    setTimeout(() => {
      document.body.classList.remove("sidebar-open");
      document.querySelector(".main__content").style = "";
    }, 500);
  };

  const handleLogout = () => {
    setloadings(true);

    setTimeout(async () => {
      await Logout(toast);
      setloadings(false);
      navigate("/login");
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <Link to="/">
          {" "}
          <img className="hidden lg:block" src={logoim} alt="Notatmrp logo" />
        </Link>
        <Link to="/">
          {" "}
          <img className="lg:hidden" src={logoimg} alt="Notatmrp logo" />
        </Link>
        <div className="sidebar-close" onClick={closeSidebar}>
          <span className="text-[30px] font-bold text-white">
            <IoClose />
          </span>
        </div>
      </div>
      <div className="sidebar__menu overflow-auto">
        {sidebarNav.map((nav, index) => (
          <Link
            to={nav.link}
            key={`nav-${index}`}
            className={`sidebar__menu__item ${
              activeIndex === index && "active"
            }`}
            onClick={closeSidebar}
          >
            <div className="sidebar__menu__item__icon">{nav.icon}</div>
            <div className="sidebar__menu__item__txt">{nav.text}</div>
          </Link>
        ))}
        <div className="sidebar__menu__item">
          <div className="sidebar__menu__item__icon">
            <span className="text-[30px] font-bold">
              <TbLogout2 />
            </span>
          </div>
          <button onClick={handleLogout} className="sidebar__menu__item__txt">
            {loadings ? <div className="loader">loadings...</div> : "Logout"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
