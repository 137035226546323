import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Autoplay, Pagination } from "swiper/modules";

const partners = [
  { name: "Adidas", image: "/images/brands/adidas.jpg" },
  { name: "Lenovo", image: "/images/brands/lenovo.jpg" },
  { name: "Boat", image: "/images/brands/boat.png" },
  { name: "Giva", image: "/images/brands/giva.png" },
  { name: "Oppo", image: "/images/brands/oppo.jpg" },
  { name: "Beardo", image: "/images/brands/Beardo.png" },
  { name: "Oneplus", image: "/images/brands/oneplus.png" },
  { name: "Urbanic", image: "/images/brands/urbanic.png" },
  { name: "Samsung", image: "/images/brands/samsung.jpg" },
  { name: "Acer", image: "/images/brands/acer.jpg" },
  { name: "Mamaearth", image: "/images/brands/mamaearth.jpg" },
];

export default function OurPartners() {
  return (
    <div className="max-w-screen-xl mx-auto px-6">
      <h2 className="text-[30px] lg:text-[40px] font-bold text-center mb-5">
        Our Partners
      </h2>

      <Swiper
        spaceBetween={10}
        slidesPerView={2}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
        autoplay={{
          delay: 1000,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {partners.map((partner, index) => (
          <SwiperSlide key={index} className="m-1">
            <div className="flex justify-center items-center h-full">
              <div className="w-full max-w-[150px] h-[150px] p-2 bg-white shadow-md rounded-md flex justify-center items-center">
                <img
                  src={partner.image}
                  alt={partner.name}
                  className="max-w-full max-h-full object-contain"
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
