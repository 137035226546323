import axios from "axios";
import Cookies from "js-cookie";
import { useState, useEffect, useRef } from "react";

const useStoresData = () => {
  const [stores, setStores] = useState(null);
  const [error, setError] = useState(null);

  // useRef to store cached store data
  const cachedStore = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("notatmrp_seller");

      if (token) {
        try {
          // Check if data is already cached
          if (cachedStore.current) {
            setStores(cachedStore.current);
          } else {
            // Fetch from API if cache is empty
            const res = await axios.get(
              `https://api.notatmrp.com/auth/retailer/stores/`,
              {
                headers: {
                  "x-api-key":
                    "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            // Store data in cache
            cachedStore.current = res.data;
            setStores(res.data);
          }
        } catch (error) {
          console.error("Failed to fetch store data:", error);
          setError("Failed to fetch store data");
        }
      } else {
        setError("No token found");
      }
    };

    fetchData();
  }, []); // Empty dependency array to only run once on mount

  return { stores, error };
};

export default useStoresData;
