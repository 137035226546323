import { useState, useEffect } from "react";
import PhoneNumberModal from "./PhoneNumberModal";
import OtpModal from "./OtpModal";

export default function LoginModel({ isOpen, onClose }) {
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(isOpen);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("");
  const [orderId, setorderID] = useState("");

  useEffect(() => {
    if (isOpen) {
      setIsPhoneModalOpen(true);
    } else {
      setIsPhoneModalOpen(false);
      setIsOtpModalOpen(false);
    }
  }, [isOpen]);

  const handleOtpRequest = (orderId, phoneNumber) => {
    setCurrentPhoneNumber(phoneNumber);
    setorderID(orderId);
    setIsPhoneModalOpen(false);
    setIsOtpModalOpen(true);
  };

  const handlePhoneClose = () => {
    setIsPhoneModalOpen(false);
    onClose();
  };

  const handleOtpClose = () => {
    setIsOtpModalOpen(false);
    onClose();
  };

  return (
    <>
      <PhoneNumberModal
        isOpen={isPhoneModalOpen}
        onClose={handlePhoneClose}
        onOtpRequest={handleOtpRequest}
      />
      <OtpModal
        isOpen={isOtpModalOpen}
        onClose={handleOtpClose}
        phoneNumber={currentPhoneNumber}
        orderId={orderId}
      />
    </>
  );
}
