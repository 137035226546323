import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function PhoneNumberModal({ isOpen, onClose, onOtpRequest }) {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const sendOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.notatmrp.com/auth/retailer/send-otp`,
        {
          phoneNumber: `+91${phoneNumber}`,
        },
        {
          headers: {
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "OTP Sent",
        description: "OTP has been sent to your mobile number.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      onOtpRequest(response.data.orderId, phoneNumber);
    } catch (error) {
      setError(
        "Failed to send OTP: " +
          (error.response?.data?.message || error.message)
      );
      toast({
        title: "Error",
        description: "Failed to send OTP.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    await sendOtp();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Continue with Phone Number</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handlePhoneSubmit}>
            <div className="flex items-center m-2">
              <span className="p-3 bg-gray-200 rounded-l-md">+91</span>
              <input
                type="tel"
                className="w-full p-3 rounded-md border text-gray-800 focus:ring-2 focus:ring-purple-700"
                value={phoneNumber}
                required
                placeholder="Please enter your mobile number"
                onChange={(e) => setPhoneNumber(e.target.value)}
                maxLength={10}
              />
            </div>
            <Button
              type="submit"
              bg="purple-500"
              color="white"
              className="w-full p-3 rounded-[20px] text-white bg-purple-600 hover:bg-purple-700 focus:ring-2 focus:ring-purple-500"
              isLoading={loading}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send OTP"}
            </Button>
          </form>
        </ModalBody>
        <ModalFooter>
          <p className="text-center text-sm text-[#969696]">
            By clicking 'Send OTP' and 'Continue with Google' you accept our{" "}
            <Link
              to="/privacy-policy"
              className="text-purple-600 hover:text-purple-800 hover:underline hover:underline-offset-auto"
            >
              Privacy policy
            </Link>
          </p>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default PhoneNumberModal;
