import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Text,
  Heading,
  useToast,
  Spinner,
  CircularProgress,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";

const Step7 = ({ onNext, user, store }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [taxes, setTaxes] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      try {
        const response = await axios.get(
          "https://api.notatmrp.com/commission-taxes",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("notatmrp_seller")}`,
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
            },
          }
        );
        setTaxes(response.data[0]);

        const feeType =
          store.StoreCategory === "669a0b5b877265140df50307"
            ? response.data[0].eventonboardingfees +
              (response.data[0].onboardinggst / 100) *
                response.data[0].eventonboardingfees
            : response.data[0].onboardingfees +
              (response.data[0].onboardinggst / 100) *
                response.data[0].onboardingfees;
        setPaymentAmount(feeType);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching payment info:", error);
        toast({
          title: "Error",
          description: "Failed to load payment information.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      }
    };
    fetchPaymentInfo();
  }, [store.StoreCategory, toast]);

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePlaceOrder = async () => {
    setButtonLoading(true);
    try {
      const orderResponse = await axios.post(
        `http://localhost:8000/payments/place-onboarding-fees`,
        {
          Stores: [
            {
              StoreID: store._id,
              totalAmount: paymentAmount,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("notatmrp_seller")}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );

      const scriptLoaded = await loadRazorpayScript();
      if (!scriptLoaded) {
        throw new Error("Razorpay SDK failed to load. Are you online?");
      }

      const options = {
        key: "rzp_live_FNSsJLdd3a6lxD",
        amount: orderResponse.data.amount,
        currency: orderResponse.data.currency,
        name: "NOTATMRP INNOVATION PRIVATE LIMITED",
        description: "Live Transaction",
        order_id: orderResponse.data.id,
        handler: async (response) => {
          try {
            const paymentResponse = await axios.post(
              "http://localhost:8000/payments/check-onboarding-status",
              {
                receipt: orderResponse.data.receipt,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              },
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("notatmrp_seller")}`,
                  "x-api-key":
                    "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
                },
              }
            );

            setPaymentDetails(paymentResponse.data);

            await axios.patch(
              `http://localhost:8000/stores/${store._id}`,
              { Payment: true },
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("notatmrp_seller")}`,
                  "x-api-key":
                    "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
                },
              }
            );

            toast({
              title: "Payment Successful",
              description: "Your payment was successful.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            // onNext();
          } catch (error) {
            console.error("Payment processing error:", error);
            toast({
              title: "Error",
              description: "Payment processing failed. Please try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        },
        prefill: {
          name: user.fullname,
          email: user.email,
          contact: user.phone,
        },
        theme: {
          color: "#F37254",
        },
        modal: {
          ondismiss: () => {
            toast({
              title: "Payment Cancelled",
              description: "You have cancelled the payment.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error during payment initiation:", error);
      toast({
        title: "Error",
        description: "Payment initiation failed. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setButtonLoading(false);
  };

  return (
    <Box maxW="700px" mx="auto" py={10} px={4} textAlign="center">
      <Heading size="lg" color="purple.700" mb={6}>
        Step {store.StoreCategory === "669a0b5b877265140df50307" ? 6 : 7}:
        Complete Payment
      </Heading>
      {loading ? (
        <Spinner color="purple.500" size="lg" />
      ) : (
        <>
          {paymentDetails ? (
            <Box mt={8} textAlign="left">
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Payment Confirmation
              </Text>
              <Text>
                <strong>Amount Pay:</strong>{" "}
                {paymentDetails.transaction.TotalAmount}
              </Text>
              <Text>
                <strong>Order Number:</strong>{" "}
                {paymentDetails.transaction.OrderNumber}
              </Text>
              <Text>
                <strong>Transaction ID:</strong>{" "}
                {paymentDetails.razorpay_order_id}
              </Text>

              <Text mt={4} fontSize="md" color="purple.600">
                Your store is under verification and will be approved within 2
                working days.
              </Text>
              <Button
                mt={6}
                colorScheme="purple"
                onClick={() => (window.location.href = `/`)}
              >
                Visit Your Store
              </Button>
            </Box>
          ) : (
            <>
              <Text fontSize="lg" color="gray.600" mb={4}>
                Your documents and agreements have been sent to our team.
              </Text>
              <Text fontSize="lg" color="gray.600" mb={6}>
                To complete the process, please proceed with the payment of{" "}
                {store.StoreCategory === "669a0b5b877265140df50307" ? (
                  <strong>
                    ₹{taxes.eventonboardingfees} + ₹{taxes.onboardinggst}% GST
                  </strong>
                ) : (
                  <strong>
                    ₹{taxes.onboardingfees} + ₹{taxes.onboardinggst}% GST
                  </strong>
                )}
                .
              </Text>
              <Button
                colorScheme="purple"
                onClick={handlePlaceOrder}
                isLoading={buttonLoading}
                spinner={
                  <CircularProgress
                    size="24px"
                    isIndeterminate
                    color="purple.500"
                  />
                }
              >
                Pay ₹{paymentAmount}
              </Button>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Step7;
