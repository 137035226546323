import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Checkbox,
  VStack,
  SimpleGrid,
  HStack,
  Heading,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";

const Step3 = ({ onNext, user, store }) => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    Business_Hours: [
      { day: "Monday", openTime: "", closeTime: "", closed: false },
      { day: "Tuesday", openTime: "", closeTime: "", closed: false },
      { day: "Wednesday", openTime: "", closeTime: "", closed: false },
      { day: "Thursday", openTime: "", closeTime: "", closed: false },
      { day: "Friday", openTime: "", closeTime: "", closed: false },
      { day: "Saturday", openTime: "", closeTime: "", closed: false },
      { day: "Sunday", openTime: "", closeTime: "", closed: false },
    ],
  });
  const [copyFromDay, setCopyFromDay] = useState("");

  useEffect(() => {
    setFormData({
      Business_Hours: store?.Business_Hours?.length
        ? store.Business_Hours
        : formData.Business_Hours,
    });
  }, [store]);

  const handleBusinessHoursChange = (index, field, value) => {
    const newBusinessHours = [...formData.Business_Hours];
    newBusinessHours[index][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      Business_Hours: newBusinessHours,
    }));
  };

  const handleCopyBusinessHours = (index) => {
    const dayToCopy = formData.Business_Hours.find(
      (hour) => hour.day === copyFromDay
    );
    if (dayToCopy) {
      const newBusinessHours = [...formData.Business_Hours];
      newBusinessHours[index] = {
        ...newBusinessHours[index],
        openTime: dayToCopy.openTime,
        closeTime: dayToCopy.closeTime,
        closed: dayToCopy.closed,
      };
      setFormData((prevData) => ({
        ...prevData,
        Business_Hours: newBusinessHours,
      }));
    } else {
      toast({
        title: "Error",
        description: "Please select a valid day to copy from.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      await axios.patch(
        `https://api.notatmrp.com/stores/${store._id}`,
        { Business_Hours: formData.Business_Hours },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("notatmrp_seller")}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );

      toast({
        title: "Business hours updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onNext(formData);
    } catch (error) {
      console.error("Error updating business hours:", error);
      toast({
        title: "Error updating business hours.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="700px" mx="auto" py={10} px={4}>
      <Heading size="lg" color="purple.700" mb={6} textAlign="center">
        Step 3: Set Business Hours
      </Heading>
      <Text textAlign="center" mb={8} color="gray.600" fontSize="lg">
        Please enter your store's Business Hours details to continue.
      </Text>
      <VStack spacing={6} align="stretch">
        {formData.Business_Hours.map((hour, index) => (
          <Box
            key={index}
            p={4}
            border="1px solid"
            borderColor="purple.200"
            borderRadius="md"
            bg="white"
            w="full"
          >
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
              <Text fontWeight="bold" color="purple.600">
                {hour.day}
              </Text>
              <HStack spacing={2}>
                <Input
                  type="time"
                  aria-label={`Open Time for ${hour.day}`}
                  value={hour.openTime}
                  onChange={(e) =>
                    handleBusinessHoursChange(index, "openTime", e.target.value)
                  }
                  isDisabled={hour.closed}
                />
                <Input
                  type="time"
                  aria-label={`Close Time for ${hour.day}`}
                  value={hour.closeTime}
                  onChange={(e) =>
                    handleBusinessHoursChange(
                      index,
                      "closeTime",
                      e.target.value
                    )
                  }
                  isDisabled={hour.closed}
                />
              </HStack>
              <Checkbox
                isChecked={hour.closed}
                onChange={(e) =>
                  handleBusinessHoursChange(index, "closed", e.target.checked)
                }
                colorScheme="purple"
              >
                Closed
              </Checkbox>
            </SimpleGrid>
            <HStack spacing={2} mt={4}>
              <Select
                placeholder="Copy from day"
                value={copyFromDay}
                onChange={(e) => setCopyFromDay(e.target.value)}
              >
                {formData.Business_Hours.map((option) => (
                  <option key={option.day} value={option.day}>
                    {option.day}
                  </option>
                ))}
              </Select>
              <Button
                colorScheme="purple"
                size="sm"
                onClick={() => handleCopyBusinessHours(index)}
              >
                Copy
              </Button>
            </HStack>
          </Box>
        ))}
        <Button colorScheme="purple" mt={6} w="full" onClick={handleSubmit}>
          Update Business Hours
        </Button>
      </VStack>
    </Box>
  );
};

export default Step3;
