import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const OrderList = ({ store }) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);

  useEffect(() => {
    if (store && store._id) {
      fetchOrders(store._id);
    }
  }, [store]);

  const fetchOrders = async (storeId) => {
    const token = Cookies.get("notatmrp_seller");

    try {
      const response = await axios.get(
        `https://api.notatmrp.com/auth/orders/store/${storeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      setOrders(response.data);
      console.log("Order", response);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const showOrderDetails = (orderId) => {
    const selectedOrder = orders.find((order) => order._id === orderId);
    setSelectedOrderDetails(selectedOrder);
  };

  const closeOrderDetails = () => {
    setSelectedOrderDetails(null);
  };

  return (
    <div className="bg-white text-black p-8 overflow-auto">
      <h1 className="text-3xl font-bold mb-6">Order List</h1>
      <table className="w-full border">
        <thead>
          <tr className="bg-purple-800 text-white">
            <th className="border p-2">Order ID</th>
            <th className="border p-2">User ID</th>
            <th className="border p-2">TransactionID</th>
            <th className="border p-2">BillAmount</th>
            <th className="border p-2">CashbackAmount</th>
            <th className="border p-2">DiscountedPrice</th>
            <th className="border p-2">isCancelled</th>
            <th className="border p-2">isCouponApplied</th>
            <th className="border p-2">Status</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order._id} className="bg-purple text-black">
              <td className="border p-2">{order._id}</td>
              <td className="border p-2">{order.UserID}</td>
              <td className="border p-2">{order.TransactionID}</td>
              <td className="border p-2">{order.BillAmount}</td>
              <td className="border p-2">{order.CashbackAmount}</td>
              <td className="border p-2">{order.DiscountedPrice}</td>
              <td className="border p-2">{order.isCancelled}</td>

              <td className="border p-2">{order.isCouponApplied}</td>
              <td className="border p-2">
                {order.isPending === false ? <p> Pending</p> : <p>Complete</p>}
              </td>
              <td className="border p-2">
                <button
                  onClick={() => showOrderDetails(order._id)}
                  className="bg-purple-900 text-white px-2 py-1 rounded-md"
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedOrderDetails && (
        <div className="mt-4 p-4 border bg-gray-100">
          <h2 className="text-lg font-bold mb-2">Order Details</h2>
          <p>
            <strong>User Name:</strong> {selectedOrderDetails.username}
          </p>
          <p>
            <strong>User Email:</strong> {selectedOrderDetails.email}
          </p>
          <p>
            <strong>User Phone Number:</strong> {selectedOrderDetails.phone}
          </p>
          <button
            onClick={closeOrderDetails}
            className="bg-purple-900 text-white px-2 py-1 rounded-md mt-2"
          >
            Close Details
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderList;
