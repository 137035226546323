import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Text,
  Heading,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";

export default function TicketManagement({ store }) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [tickets, setTickets] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [ticketToDelete, setTicketToDelete] = useState(null);
  const cancelRef = useRef();
  const toast = useToast();
  const token = Cookies.get("notatmrp_seller");

  useEffect(() => {
    if (!store || !store._id) return;

    const fetchTickets = async () => {
      try {
        const response = await axios.get(
          `https://api.notatmrp.com/tickets/all/store/${store._id}`,
          {
            headers: {
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTickets(response.data);
      } catch (error) {
        toast({
          title: "Error.",
          description: "There was an error fetching the tickets.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchTickets();
  }, [store, token, toast]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = editMode
      ? `https://api.notatmrp.com/tickets/update/${editId}`
      : "https://api.notatmrp.com/tickets/add";
    const method = editMode ? "put" : "post";

    try {
      const response = await axios({
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key":
            "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
        },
        data: { name, price },
      });

      const updatedTicket = response.data;
      setTickets(
        editMode
          ? tickets.map((ticket) =>
              ticket._id === editId ? updatedTicket : ticket
            )
          : [...tickets, updatedTicket]
      );
      setName("");
      setPrice("");
      setEditMode(false);
      setEditId(null);
      toast({
        title: "Ticket saved.",
        description: "Your ticket has been saved successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error.",
        description: "There was an error saving your ticket.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (ticket) => {
    setName(ticket.Name);
    setPrice(ticket.price);
    setEditMode(true);
    setEditId(ticket._id);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://api.notatmrp.com/tickets/remove/${ticketToDelete}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );

      setTickets(tickets.filter((ticket) => ticket._id !== ticketToDelete));
      toast({
        title: "Ticket deleted.",
        description: "Your ticket has been deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteDialogOpen(false);
      setTicketToDelete(null);
    } catch (error) {
      toast({
        title: "Error.",
        description: "There was an error deleting your ticket.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const openDeleteDialog = (id) => {
    setTicketToDelete(id);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setTicketToDelete(null);
  };

  if (!store || !store._id) {
    return (
      <Box bg="purple.50" p={6} minH="100vh">
        <Text color="purple.800">Store information is not available.</Text>
      </Box>
    );
  }

  return (
    <Box bg="purple.50" p={6} minH="100vh">
      <VStack spacing={6}>
        <Box bg="white" p={6} rounded="md" w="full" color="purple.800">
          <Heading mb={6}>{editMode ? "Edit Ticket" : "Add Ticket"}</Heading>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="flex-start">
              <FormControl>
                <FormLabel>Ticket Name</FormLabel>
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel>Price</FormLabel>
                <Input
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </FormControl>
              <Button type="submit" colorScheme="purple" width="full">
                {editMode ? "Update Ticket" : "Add Ticket"}
              </Button>
            </VStack>
          </form>
        </Box>
        <Box bg="white" p={6} rounded="md" w="full" color="purple.800">
          <Heading mb={6}>Tickets</Heading>
          <VStack spacing={4} align="stretch">
            {tickets.map((ticket) => (
              <HStack key={ticket._id} justify="space-between" w="full">
                <Text>{ticket.Name}</Text>
                <Text>₹{ticket.price}</Text>
                <HStack>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => handleEdit(ticket)}
                  >
                    Edit
                  </Button>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => openDeleteDialog(ticket._id)}
                  >
                    Delete
                  </Button>
                </HStack>
              </HStack>
            ))}
          </VStack>
        </Box>
      </VStack>

      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDeleteDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Ticket
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this ticket? This action cannot be
              undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeDeleteDialog}>
                No
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
