import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const useRetialerData = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  // useRef to store the cached user data
  const cachedUser = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("notatmrp_seller");

      if (token) {
        try {
          let userData = cachedUser.current; // Read from cache first

          if (!userData) {
            // If cache is empty, fetch from API
            const res = await axios.get(
              `https://api.notatmrp.com/auth/retailer/retailerdetail`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "x-api-key":
                    "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
                },
              }
            );
            userData = res.data;
            cachedUser.current = userData; // Store in cache
          }

          setUser(userData);
        } catch (error) {
          console.error("Failed to fetch user data:", error);
          setError("Failed to fetch user data");
        }
      } else {
        setError("No token found");
      }
    };

    fetchData();
    // Remove the dependency array to run on every render cycle
  }, []);

  return { user, error };
};

export default useRetialerData;
