import React from "react";
import step from "../../assets/images/step.png";
import step2 from "../../assets/images/step2.png";
export default function WorkPart() {
  return (
    <div className="max-w-screen-xl mx-auto  px-6">
      <h2 className="text-[30px] lg:text-[40px] font-bold text-center mb-5">
        How does NOTATMRP Work?
      </h2>
      <div className="md:flex items-center gap-5  justify-center">
        <div>
          <img
            src={step}
            alt="Step with QR"
            className="w-full h-50 object-contain shadow-lg rounded-xl mb-2"
          />
        </div>
        <div>
          <img
            src={step2}
            alt="Step with Pay"
            className="w-full h-50 object-contain shadow-lg rounded-xl mb-2"
          />
        </div>
      </div>
    </div>
  );
}
