import images from "./images";

const data = {
  user: {
    name: "Rk Sarees",
    img: images.avt,
  },
  summary: [
    {
      title: "Sales",
      subtitle: "Total sales today",
      value: "0",
      percent: 70,
    },
    {
      title: "Orders",
      subtitle: "Total orders today",
      value: "0",
      percent: 79,
    },
    {
      title: "Revenue",
      subtitle: "Total revenue today",
      value: "0",
      percent: 30,
    },
    {
      title: "Store Visits",
      subtitle: "Total visits Month",
      value: "0",
      percent: 45,
    },
  ],
  revenueSummary: {
    title: "Revenue",
    value: "0",
    chartData: {
      labels: ["May", "Jun", "July", "Aug", "May", "Jun", "July", "Aug"],
      data: [300, 300, 280, 300, 200, 300, 280, 350],
    },
  },
  overall: [
    {
      value: "0",
      title: "Orders",
    },
    {
      value: "0",
      title: "click",
    },
    {
      value: "0",
      title: "Impression",
    },
    {
      value: "0",
      title: "Revenue",
    },
  ],
  // revenueByChannel: [
  //   {
  //     title: "Direct",
  //     value: 70,
  //   },
  //   {
  //     title: "Search",
  //     value: 40,
  //   },
  //   // {
  //   //   title: "Referal",
  //   //   value: 60,
  //   // },
  //   // {
  //   //   title: "Social",
  //   //   value: 30,
  //   // },
  // ],
  revenueByMonths: {
    labels: [
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
    ],
    data: [250, 200, 300, 280, 100, 220, 310, 190, 200, 120, 250, 350],
  },
};

export default data;
