import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  VStack,
  Heading,
  Text,
  useToast,
  SimpleGrid,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";

const Step4 = ({ onNext, store }) => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    GST_Number: "",
    Pan_Number: "",
    accountNumber: {
      accountname: "",
      accountno: "",
      bankname: "",
      IFSC: "",
      accounttype: "SAVING",
    },
  });

  useEffect(() => {
    // Populate with existing data or default structure if empty
    setFormData({
      GST_Number: store?.GST_Number || "",
      Pan_Number: store?.Pan_Number || "",
      accountNumber: store?.accountNumber || {
        accountname: "",
        accountno: "",
        bankname: "",
        IFSC: "",
        accounttype: "SAVING",
      },
    });
  }, [store]);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAccountChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      accountNumber: {
        ...prevData.accountNumber,
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      await axios.patch(
        `https://api.notatmrp.com/stores/${store._id}`,
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("notatmrp_seller")}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "Details updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onNext(formData);
    } catch (error) {
      console.error("Error updating details:", error);
      toast({
        title: "Error updating details.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="700px" mx="auto" py={10} px={4}>
      {store.StoreCategory === "669a0b5b877265140df50307" ? (
        <Heading size="lg" color="purple.700" mb={6} textAlign="center">
          Step 3: Enter GST and Bank Details
        </Heading>
      ) : (
        <Heading size="lg" color="purple.700" mb={6} textAlign="center">
          Step 4: Enter GST and Bank Details{" "}
        </Heading>
      )}
      <Text textAlign="center" mb={8} color="gray.600" fontSize="lg">
        Please enter your GST Number, PAN Number, and bank account details.
      </Text>
      <VStack spacing={6} align="stretch">
        <Box>
          <Text fontWeight="bold" color="purple.600" mb={2}>
            GST Number
          </Text>
          <Input
            placeholder="GST Number"
            value={formData.GST_Number}
            onChange={(e) => handleChange("GST_Number", e.target.value)}
          />
        </Box>

        <Box>
          <Text fontWeight="bold" color="purple.600" mb={2}>
            PAN Number
          </Text>
          <Input
            placeholder="PAN Number"
            value={formData.Pan_Number}
            onChange={(e) => handleChange("Pan_Number", e.target.value)}
          />
        </Box>

        <Box>
          <Heading size="md" color="purple.600" mb={4}>
            Bank Account Details
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <Box>
              <Text fontWeight="bold">Account Name</Text>
              <Input
                placeholder="Account Name"
                value={formData.accountNumber.accountname}
                onChange={(e) =>
                  handleAccountChange("accountname", e.target.value)
                }
              />
            </Box>
            <Box>
              <Text fontWeight="bold">Account Number</Text>
              <Input
                placeholder="Account Number"
                value={formData.accountNumber.accountno}
                onChange={(e) =>
                  handleAccountChange("accountno", e.target.value)
                }
              />
            </Box>
            <Box>
              <Text fontWeight="bold">Bank Name</Text>
              <Input
                placeholder="Bank Name"
                value={formData.accountNumber.bankname}
                onChange={(e) =>
                  handleAccountChange("bankname", e.target.value)
                }
              />
            </Box>
            <Box>
              <Text fontWeight="bold">IFSC Code</Text>
              <Input
                placeholder="IFSC Code"
                value={formData.accountNumber.IFSC}
                onChange={(e) => handleAccountChange("IFSC", e.target.value)}
              />
            </Box>
            <Box>
              <Text fontWeight="bold">Account Type</Text>
              <Select
                placeholder="Select Account Type"
                value={formData.accountNumber.accounttype}
                onChange={(e) =>
                  handleAccountChange("accounttype", e.target.value)
                }
              >
                <option value="SAVING">Saving</option>
                <option value="CURRENT">Current</option>
              </Select>
            </Box>
          </SimpleGrid>
        </Box>

        <Button colorScheme="purple" mt={6} w="full" onClick={handleSubmit}>
          Update Details
        </Button>
      </VStack>
    </Box>
  );
};

export default Step4;
