/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  VStack,
  Heading,
  Text,
  useToast,
  FormControl,
  FormLabel,
  Link,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import axios from "axios";
import Cookies from "js-cookie";

const Step5 = ({ onNext, store }) => {
  const toast = useToast();
  const [uploading, setUploading] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [formData, setFormData] = useState({
    logourl: "",
    thumbnailurl: "",
    interiorimageurl: [],
    gstCertificateurl: "",
    pancardurl: "",
    chequeurl: "",
    fssaiurl: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      fssaiurl: store?.fssaiurl || "",
      logourl: store?.logourl || "",
      thumbnailurl: store?.thumbnailurl || "",
      interiorimageurl: store?.interiorimageurl || [],
      gstCertificateurl: store?.gstCertificateurl || "",
      pancardurl: store?.pancardurl || "",
      chequeurl: store?.chequeurl || "",
    });
  }, [store]);

  const handleDrop = useCallback(
    (acceptedFiles, name) => {
      setUploading((prev) => ({ ...prev, [name]: true }));
      const folderPath = `storesdocuments/${
        store.StoreName - store._id
      }/${name}`; // Create folder path with store._id
      const uploadPromises = acceptedFiles.map((file) => {
        const storageRef = ref(storage, `${folderPath}/${file.name}`);
        return uploadBytes(storageRef, file)
          .then(() => getDownloadURL(storageRef))
          .then((url) => {
            setFormData((prevData) => ({
              ...prevData,
              [`${name}url`]:
                name === "interiorImages"
                  ? [...prevData.interiorimageurl, url]
                  : url,
            }));
            setUploadProgress((prev) => ({
              ...prev,
              [name]:
                name === "interiorImages"
                  ? [...(prev.interiorImages || []), `${file.name} uploaded`]
                  : `${file.name} uploaded`,
            }));
          });
      });

      Promise.all(uploadPromises)
        .then(() => {
          setUploading((prev) => ({ ...prev, [name]: false }));
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setUploading((prev) => ({ ...prev, [name]: false }));
        });
    },
    [store._id]
  );

  const handleSubmit = async () => {
    // const missingDocuments = requiredDocuments.filter(
    //   (item) => !formData[item.name] && !store[item.name]
    // );

    // if (missingDocuments.length > 0) {
    //   const missingNames = missingDocuments
    //     .map((item) => item.label)
    //     .join(", ");
    //   toast({
    //     title: `Please upload the following required documents: ${missingNames}.`,
    //     status: "error",
    //     duration: 4000,
    //     isClosable: true,
    //   });
    //   return;
    // }

    setIsSubmitting(true);
    try {
      await axios.patch(
        `https://api.notatmrp.com/stores/${store._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("notatmrp_seller")}`,
            "x-api-key":
              "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
          },
        }
      );
      toast({
        title: "Photos uploaded successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onNext(formData);
    } catch (error) {
      console.error("Error updating details:", error);
      toast({
        title: "Error uploading photos.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const requiredDocuments = [
    { label: "Store Logo", name: "logourl" },
    { label: "Thumbnail Image (shown on NOT@MRP)", name: "thumbnailurl" },
    { label: "GST Certificate", name: "gstCertificateurl" },
    { label: "PAN Card", name: "pancardurl" },
    { label: "Cheque", name: "chequeurl" },
  ];

  // Show FSSAI field only for specific categories
  const showFssai = [
    "668f07d40c9510198d818817",
    "668503d9c7e64f95a0ac4a44",
  ].includes(store.StoreCategory);
  if (showFssai) {
    requiredDocuments.push({ label: "FSSAI Certificate", name: "fssaiurl" });
  }

  return (
    <Box maxW="700px" mx="auto" py={10} px={4}>
      {store.StoreCategory === "669a0b5b877265140df50307" ? (
        <Heading size="lg" color="purple.700" mb={6} textAlign="center">
          Step 4: Upload Documents
        </Heading>
      ) : (
        <Heading size="lg" color="purple.700" mb={6} textAlign="center">
          Step 5: Upload Documents
        </Heading>
      )}

      <Text textAlign="center" mb={8} color="gray.600" fontSize="lg">
        Please upload your store logo, thumbnail image, and other necessary
        documents.
      </Text>
      <VStack spacing={6} align="stretch">
        {requiredDocuments.map((item) => {
          const { getRootProps, getInputProps } = useDropzone({
            accept: "image/*,application/pdf",
            onDrop: (acceptedFiles) => handleDrop(acceptedFiles, item.name),
          });
          const isRequired = !formData[item.name] && !store[item.name];

          return (
            <FormControl key={item.name} isRequired={isRequired}>
              <FormLabel>{item.label}</FormLabel>
              <Box
                p={4}
                borderWidth={2}
                borderStyle="dashed"
                borderColor="gray.300"
                rounded="md"
                textAlign="center"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {formData[item.name] ? (
                  <Text>
                    <Link
                      href={formData[item.name]}
                      color="teal.500"
                      isExternal
                    >
                      View current file
                    </Link>
                  </Text>
                ) : (
                  <Text>
                    Drag 'n' drop your {item.label.toLowerCase()} here, or click
                    to select a file
                  </Text>
                )}
                {uploadProgress[item.name] && (
                  <Text>{uploadProgress[item.name]}</Text>
                )}
              </Box>
            </FormControl>
          );
        })}

        <Button
          onClick={handleSubmit}
          colorScheme="purple"
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </VStack>
    </Box>
  );
};

export default Step5;
