import React from "react";
import Navbar from "./components/Navbar";
import TopPart from "./components/TopPart";
import Midpart from "./components/Midpart";
import InfoPart from "./components/InfoPart";
import Footer from "./components/Footer";
import CategoryPart from "./components/CategoryPart";
import WorkPart from "./components/WorkPart";
import Cookies from "js-cookie";
import OurPartners from "./components/OurPartners";

export default function Home() {
  const token = Cookies.get("notatmrp_seller");

  if (token) {
    window.location.href = "/";
  }
  return (
    <section>
      <Navbar />
      <div className="min-h-[35vh] mb-[20px]">
        <TopPart />
      </div>
      <div className="min-h-[50vh]">
        <Midpart />
      </div>
      <div className="min-h-[50vh]">
        <CategoryPart />
      </div>
      <div className="min-h-[50vh]">
        <OurPartners />
      </div>
      <div className="min-h-[50vh]">
        <WorkPart />
      </div>
      <div className="min-h-[50vh] mb-5">
        <InfoPart />
      </div>
      <Footer />
    </section>
  );
}
